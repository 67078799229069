.floorPlansTitleFirst {
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    padding: 1.3em 0 .75em 0;
    margin: 0;
    font-size: 1.5em;
    box-sizing: border-box;
    text-align: left;
}

.floorPlansTitle {
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    padding: .5em 0 .75em 0;
    margin: 0;
    font-size: 1.5em;
    box-sizing: border-box;
    text-align: left;
}

.blueBackground {
    background-color: #0072bb;
}

.thumbnailCategoryPlans {
    padding: 0 0 0 0;
    margin: 0 1.66% 1.5em 1.66%;
    box-sizing: border-box;
    width: 30%;
    display: flex;
    flex-direction: column;
    position: relative;
    border: solid #0072bb .05em;
}

.thumbnailCategoryPlans a {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: black;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
}

.backgroundImage {
    box-sizing: border-box;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.thumbnailText {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: rgb(255, 255, 255) transparent;
    background: rgba(255, 255, 255, 0.5);
}

.thumbnailTitle {
    font-size: 1em;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    box-sizing: border-box;
    padding: .75em;
    font-weight: 700;
    text-align: center;
    margin: 0;
}

.detailsAppearFloorPlans {
    padding: 0 .75em .75em .75em;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    display: none;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 1em;
    flex: 1;
}

.detailsHolder {
    list-style: square;
    padding: 0 .75em;
    margin: 0;
    box-sizing: border-box;
}

.floorPlanDetail {
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 1em;
    box-sizing: border-box;
}

.thumbnailText:hover {
    flex: 1;
}

.thumbnailText:hover .detailsAppearFloorPlans {
    display: flex;
    justify-content: center;
    align-items: center;
}

.thumbnailCategoryPlans:hover .thumbnailText {
    flex: 1;
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .floorPlansTitleFirst {
        font-size: 1.4em;
    }
    .floorPlansTitle {
        font-size: 1.4em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .floorPlansTitleFirst {
        font-size: 1.4em;
    }
    .floorPlansTitle {
        font-size: 1.4em;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    .floorPlansTitleFirst {
        font-size: 1.3em;
    }
    .floorPlansTitle {
        font-size: 1.3em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .floorPlansTitleFirst {
        font-size: 1.3em;
    }
    .floorPlansTitle {
        font-size: 1.3em;
    }
}


@media all and (max-width: 395.99px) {
    .floorPlansTitleFirst {
        font-size: 1.2em;
    }
    .floorPlansTitle {
        font-size: 1.2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .floorPlansTitleFirst {
        font-size: 1.2em;
    }
    .floorPlansTitle {
        font-size: 1.2em;
    }
}

@media all and (max-width: 850.99px) and (min-width: 501px){
    .thumbnailCategoryPlans {
        width: 46%;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1275.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 1275.99px) and (min-width: 751px) {
    .thumbnailCategoryPlans {
        width: 46%;
    }
}

@media all and (max-width: 775.99px) and (min-width: 501px){
    .detailsAppearFloorPlans {
        font-size: .8em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 1160.99px) and (min-width: 751px) {
    .detailsAppearFloorPlans {
        font-size: .8em;
    }
}

@media all and (max-width: 700.99px) {
    .menuSwitchNoDisplay {
        display: none;
    }
    .thumbnailText:hover {
        flex: none;
    }
    .thumbnailCategoryPlans:hover .thumbnailText {
        flex: none;
    }
    .detailsAppearFloorPlans {
        padding: 0;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1050.99px), (-ms-high-contrast: active) and (max-width: 1050.99px) {
    .menuSwitchNoDisplay {
        display: none;
    }
    .thumbnailText:hover {
        flex: none;
    }
    .thumbnailCategoryPlans:hover .thumbnailText {
        flex: none;
    }
    .detailsAppearFloorPlans {
        padding: 0;
    }
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .detailsAppearFloorPlans {
        font-size: 1.1em;
    }
    .thumbnailTitle {
        font-size: 1.1em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .detailsAppearFloorPlans {
        font-size: 1.1em;
    }
    .thumbnailTitle {
        font-size: 1.1em;
    }
}

@media all and (max-width: 500.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .thumbnailCategoryPlans {
        width: 100%;
        margin: 0 0 1.5em 0;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px), (-ms-high-contrast: active) and (max-width: 750.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .thumbnailCategoryPlans {
        width: 100%;
        margin: 0 0 1.5em 0;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    .detailsAppearFloorPlans {
        font-size: .95em;
    }
    .thumbnailTitle {
        font-size: .95em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .detailsAppearFloorPlans {
        font-size: .95em;
    }
    .thumbnailTitle {
        font-size: .95em;
    }
}

@media all and (max-width: 395.99px) {
    .detailsAppearFloorPlans {
        font-size: .85em;
    }
    .thumbnailTitle {
        font-size: .85em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .detailsAppearFloorPlans {
        font-size: .85em;
    }
    .thumbnailTitle {
        font-size: .85em;
    }
}
