.blueHeading {
    color: #0072bb;
    text-transform: none;
    font-size: 1.4em;
    box-sizing: border-box;
}

.buildingTip {
    font-size: .9em;
    padding: 0 0 1.35em 0;
    box-sizing: border-box;
}

.actionText {
    font-size: 1em;
    box-sizing: border-box;
}

.largeBlueButton {
    display: flex;
    box-sizing: border-box;
    padding: 1em .75em 2em .75em;
    margin: 0;
    justify-content: center;
}

.largeButtonText {
    background-color: #0072bb;
    color: white;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    border-radius: .25em;
    padding: .25em;
    margin: 0;
    box-sizing: border-box;
    font-size: 1.26em;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}

.largeButtonText a {
    text-decoration: none;
    color: white;
    box-sizing: border-box;
    cursor: pointer;
}

.rightImage {
    width: 100%;
    margin: 0;
    padding: 0 0 0 1.5em;
    box-sizing: border-box;
    flex: 1;
 }

.leftImage {
    width: 100%;
    margin: 0;
    padding: 0 1.5em 0 0;
    box-sizing: border-box;
    flex: 1.5;
}

.columnContainer {
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.textFlexObject {
    flex: 1.5;
    box-sizing: border-box;
}

@media all and (max-width: 1025.99px) {
    .leftImage {
        flex: 1.25;
        padding: 0 1.25em 0 0;
    }
    .rightImage {
        padding: 0 0 0 1.25em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1535.99px), (-ms-high-contrast: active) and (max-width: 1535.99px) {
    .leftImage {
        flex: 1.25;
        padding: 0 1.25em 0 0;
    }
    .rightImage {
        padding: 0 0 0 1.25em;
    }
}

@media all and (max-width: 500.99px) {
    .buildingTip {
        padding: 0 0 1.35em 0;
        font-size: 1em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px), (-ms-high-contrast: active) and (max-width: 750.99px) {
    .buildingTip {
        padding: 0 0 1.35em 0;
        font-size: 1em;
    }
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .blueHeading {
        font-size: 1.3em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .blueHeading {
        font-size: 1.3em;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    .blueHeading {
        font-size: 1.25em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .blueHeading {
        font-size: 1.25em;
    }
}


@media all and (max-width: 395.99px) {
    .blueHeading {
        font-size: 1.2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .blueHeading {
        font-size: 1.2em;
    }
}

@media all and (max-width: 775.99px) and (min-width: 501px){
    .largeButtonText {
        font-size: 1.2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 1160.99px) and (min-width: 751px) {
    .largeButtonText {
        font-size: 1.2em;
    }
}


@media all and (max-width: 500.99px) and (min-width: 396px) {
    .largeButtonText {
        font-size: 1.15em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 591px) {
    .largeButtonText {
        font-size: 1.15em;
    }
}

@media all and (max-width: 395.99px) {
    .largeButtonText {
        font-size: 1em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .largeButtonText {
        font-size: 1em;
    }
}
