@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700|Raleway:600,700');

html {
    box-sizing: border-box;
    max-width: 100%;
}

body {
    box-sizing: border-box;
    max-width: 100%;
    min-height: 100vh;
    background-color: #f9f9f9;
    display: flex;
    font-size: 18pt;
    font-family: 'Lato', sans-serif;
    padding: 0;
    margin: 0;
}

.lightboxOpenClass {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: fixed;
}

#root {
    box-sizing: border-box;
    min-height: 100vh;
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    background-color: #f9f9f9;
}

#root > div {
    box-sizing: border-box;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    background-color: #f9f9f9;
}

.pageWrapper {
    box-sizing: border-box;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    background-color: #f9f9f9;
}

.headerContainer {
    box-sizing: border-box;
    width: 100%;
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
}

.contentWrapper {
    box-sizing: border-box;
    width: 100%;
    flex: 1;
    margin: 0;
    padding: 0;
    font-size: 1em;
}

.fullWidthPhoto {
    box-sizing: border-box;
    padding: 0 0 0 0;
    margin: 0 0 1.5em 0;
    width: 100%;
}

.fullWidthPhoto img {
    border-bottom: solid .5em #0072bb;
}

.pageContentWithGrey {
    box-sizing: border-box;
    padding: 0 0 .65em 0;
    margin: 0;
}

.sidePadding {
    box-sizing: border-box;
    padding: 0 2em 2em 2em;
    margin: 0;
}

.pageContent {
    box-sizing: border-box;
    padding: 0 2em .65em 2em;
    margin: 0;
}

.footerContainer {
    box-sizing: border-box;
    width: 100%;
    flex: none;
    background-color: #4d4d4d;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2em 2.75em 2.5em 2.75em;
    margin: 0;
    font-size: .75em;
}

.footerContainer a {
    color: white;
    text-decoration: white;
}

.italic {
    font-style: italic;
}

h1 {
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    padding: 0 0 .4em 0;
    margin: 0;
    font-size: 1.65em;
    box-sizing: border-box;
}

.contentPadding {
    padding: 0 0 1.14em 0;
}

h2 {
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    padding: 0 0 .4em 0;
    margin: 0;
    font-size: 1.5em;
    box-sizing: border-box;
    text-align: left;
}

h3 {
    font-size: 1em;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    box-sizing: border-box;
    padding: 0 0 .4em 0;
    font-weight: 700;
    text-align: left;
    margin: 0;
}

.contactPrompt {
    font-size: 1em;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    box-sizing: border-box;
    padding: 0 0 .4em 0;
    font-weight: 700;
    text-align: left;
    margin: 0;
}

.rightText {
    text-align: right;
}

p {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-size: 1.1em;
}

.paragraphText {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-size: 1.1em;
}

.multipleParagraphText {
    box-sizing: border-box;
    padding: .75em 0 0 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-size: 1.1em;
}

.lastParagraphText {
    box-sizing: border-box;
    padding: .75em 0 2em 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-size: 1.1em;
}

.noBottomPadding {
    padding-bottom: 0;
}

.noTopPadding {
    padding-top: 0;
}

img {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.noDisplay {
    display: none;
}

.hidden {
    visibility: hidden;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    body {
        font-size: 22pt;
    }
}
@media all and (max-width: 1245.99px) and (min-width: 1026px){
    body {
        font-size: 16pt;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1865.99px) and (min-width: 1535px), (-ms-high-contrast: active) and (max-width: 1865.99px) and (min-width: 1536px) {
    body {
        font-size: 20pt;
    }
}

@media all and (max-width: 1025.99px) and (min-width: 851px) {
    body {
        font-size: 14pt;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1535.99px) and (min-width: 1276px), (-ms-high-contrast: active) and (max-width: 1535.99px) and (min-width: 1275px){
    body {
        font-size: 18pt;
    }
}

@media all and (max-width: 850.99px) and (min-width: 776px) {
    .rightText {
        text-align: left;
    }
    body {
        font-size: 13pt;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1275.99px) and (min-width: 1161px), (-ms-high-contrast: active) and (max-width: 1275.99px) and (min-width: 1161px) {
    .rightText {
        text-align: left;
    }
    body {
        font-size: 17pt;
    }
}

@media all and (max-width: 775.99px) and (min-width: 501px){
    .rightText {
        text-align: left;
    }
    p {
        font-size: 1.25em;
    }
    .paragraphText {
        font-size: 1.25em;
    }

    .multipleParagraphText {
        font-size: 1.25em;
    }

    .lastParagraphText {
        font-size: 1.25em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 1160.99px) and (min-width: 751px) {
    .rightText {
        text-align: left;
    }
    p {
        font-size: 1.25em;
    }

    .paragraphText {
        font-size: 1.25em;
    }

    .multipleParagraphText {
        font-size: 1.25em;
    }

    .lastParagraphText {
        font-size: 1.25em;
    }
}

@media all and (max-width: 775.99px) and (min-width: 601px) {
    body {
        font-size: 11pt;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px) and (min-width: 901px), (-ms-high-contrast: active) and (max-width: 1160.99px) and (min-width: 901px){
    body {
        font-size: 15pt;
    }
}

@media all and (max-width: 700.99px) {
    .menuSwitchNoDisplay {
        display: none;
    }
    h1 {
        text-align: center;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1050.99px), (-ms-high-contrast: active) and (max-width: 1050.99px) {
    .menuSwitchNoDisplay {
        display: none;
    }
    h1 {
        text-align: center;
    }
}

@media all and (max-width: 600.99px) {
    body {
        font-size: 11pt;
    }
    .fullWidthPhoto {
        margin: 0 0 1em 0;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 900.99px), (-ms-high-contrast: active) and (max-width: 900.99px) {
    body {
        font-size: 15pt;
    }
    .fullWidthPhoto {
        margin: 0 0 1em 0;
    }
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    h2 {
        font-size: 1.4em;
    }
    h1 {
        font-size: 1.55em;
    }
    h3 {
        font-size: .95em;
    }
    .contactPrompt {
        font-size: .95em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    h2 {
        font-size: 1.4em;
    }
    h1 {
        font-size: 1.55em;
    }
    h3 {
        font-size: .95em;
    }
    .contactPrompt {
        font-size: .95em;
    }
}

@media all and (max-width: 500.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .sidePadding {
        padding: 0 1.5em 2em 1.5em;
    }
    .pageContent {
        padding: 0 1.5em .65em 1.5em;
    }
    .footerContainer {
        padding: 2em 2.06em 2.5em 2.06em;
    }
    .rightText {
        text-align: left;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px), (-ms-high-contrast: active) and (max-width: 750.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .sidePadding {
        padding: 0 1.5em 2em 1.5em;
    }
    .pageContent {
        padding: 0 1.5em .65em 1.5em;
    }
    .footerContainer {
        padding: 2em 2.06em 2.5em 2.06em;
    }
    .rightText {
        text-align: left;
    }
}

@media all and (max-width: 500.99px) and (min-width: 396px) {
    p {
        font-size: 1.14em;
    }
    .paragraphText {
        font-size: 1.14em;
    }
    .multipleParagraphText {
        font-size: 1.14em;
    }
    .lastParagraphText {
        font-size: 1.14em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 591px) {
    p {
        font-size: 1.14em;
    }
    .paragraphText {
        font-size: 1.14em;
    }
    .multipleParagraphText {
        font-size: 1.14em;
    }
    .lastParagraphText {
        font-size: 1.14em;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    h2 {
        font-size: 1.3em;
    }
    h1 {
        font-size: 1.45em;
    }
    h3 {
        font-size: .9em;
    }
    .contactPrompt {
        font-size: .9em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    h2 {
        font-size: 1.3em;
    }
    h1 {
        font-size: 1.45em;
    }
    h3 {
        font-size: .9em;
    }
    .contactPrompt {
        font-size: .9em;
    }
}

@media all and (max-width: 395.99px) {
    h2 {
        font-size: 1.2em;
    }
    h1 {
        font-size: 1.35em;
    }
    h3 {
        font-size: .85em;
    }
    .contactPrompt {
        font-size: .85em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    h2 {
        font-size: 1.2em;
    }
    h1 {
        font-size: 1.35em;
    }
    h3 {
        font-size: .85em;
    }
    .contactPrompt {
        font-size: .85em;
    }
}

@media all and (max-width: 395.99px) {
    p {
        font-size: 1.1em;
    }
    .paragraphText {
        font-size: 1.1em;
    }
    .multipleParagraphText {
        font-size: 1.1em;
    }
    .lastParagraphText {
        font-size: 1.1em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    p {
        font-size: 1.1em;
    }
    .paragraphText {
        font-size: 1.1em;
    }
    .multipleParagraphText {
        font-size: 1.1em;
    }
    .lastParagraphText {
        font-size: 1.1em;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .contentWrapper {
        flex: none !important;
    }
    .fullWidthPhoto {
        flex: none !important;
    }
    .flexNoneIE {
        flex: none !important;
    }
}




