.buildingTip {
    font-size: .9em;
    padding: 0 0 1.35em 0;
    box-sizing: border-box;
    margin: 0;
}

.blueHeading {
    color: #0072bb;
    text-transform: none;
    font-size: 1.4em;
    box-sizing: border-box;
    margin: 0;
}

.blueHeadingUppercase {
    color: #0072bb;
    text-transform: uppercase;
    font-size: 1.6em;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.firstItem {
    padding: 2em 0 1.35em 0;
    box-sizing: border-box;
}

.listColumnContainer {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1em;
}

.processList {
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.processListItem {
    padding: 0 .5em .25em .5em;
    margin: 0;
}

.wheatBullet {
    width: 1em;
    height: 1em;
    flex: none;
    padding: .27em 0 0 0;
    margin: 0 .5em 0 0;
    box-sizing: border-box;
}

.processSublist {
    list-style: square;
    color: #0072bb;
    margin: 0;
    padding: 0 0 0 4em;
    box-sizing: border-box;
    font-size: .93em;
}

.processSublistItem {
    margin: 0;
    padding: .15em 0 0 0;
}

.blackText {
    color: black;
    box-sizing: border-box;
}

@media all and (max-width: 500.99px) {
    .buildingTip {
        padding: 0 0 1.35em 0;
        font-size: .95em;
    }
    .firstItem {
        padding: 2em 0 1em 0;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px), (-ms-high-contrast: active) and (max-width: 750.99px) {
    .buildingTip {
        padding: 0 0 1.35em 0;
        font-size: .95em;
    }
    .firstItem {
        padding: 2em 0 1em 0;
    }
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .blueHeading {
        font-size: 1.3em;
    }
    .blueHeadingUppercase {
        font-size: 1.5em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .blueHeading {
        font-size: 1.3em;
    }
    .blueHeadingUppercase {
        font-size: 1.5em;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    .blueHeading {
        font-size: 1.25em;
    }
    .blueHeadingUppercase {
        font-size: 1.45em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .blueHeading {
        font-size: 1.25em;
    }
    .blueHeadingUppercase {
        font-size: 1.45em;
    }
}


@media all and (max-width: 395.99px) {
    .blueHeading {
        font-size: 1.2em;
    }
    .blueHeadingUppercase {
        font-size: 1.4em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .blueHeading {
        font-size: 1.2em;
    }
    .blueHeadingUppercase {
        font-size: 1.4em;
    }
}

