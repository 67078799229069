.footerContacts {
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    text-align: center;
}

.footerText {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.footerTextLine {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.siteMap {
    box-sizing: border-box;
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 1em 0 0 0;
    margin: 0;
    font-size: 1.55em;
    flex-wrap: wrap;
}

.siteMapLink {
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 0 0 .3em;
    margin: 0;
}

.siteMapLink a {
    text-decoration: none;
    color: white;
}

.verticalBarRight {
    box-sizing: border-box;
    border-right: .125em solid white;
    padding: 0 .3em 0 0;
    margin: 0;
}

.verticalBarRight a {
    text-decoration: none;
    color: white;
}

.firstRow {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    justify-content: center;
}

.secondRow {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    justify-content: center;
}

.footerCopyright {
    text-align: center;
    box-sizing: border-box;
    padding: 1.6em 0 2.1em 0;
    margin: 0;
}

.topPadding {
    padding-top: .75em;
}

.breakTogether {
    white-space: nowrap;
}

.winnovationsLink {
    text-decoration: underline;
}

.footerIcons {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.holder {
    padding: 0;
    margin: 0 1.75em;
    box-sizing: border-box;
}

.iconSize {
    width: 3.25em;
    height: 3.25em;
    padding: 0;
    margin: 0;
}

@media all and (max-width: 1025px) {
    .footerContacts {
        justify-content: space-between;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1535px), (-ms-high-contrast: active) and (max-width: 1535px) {
    .footerContacts {
        justify-content: space-between;
    }
}

@media all and (max-width: 850.99px) and (min-width: 661px) {
    .siteMap {
        font-size: 1.5em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1275.99px) and (min-width: 991px), (-ms-high-contrast: active) and (max-width: 1275.99px) and (min-width: 991px) {
    .siteMap {
        font-size: 1.5em;
    }
}

@media all and (max-width: 700.99px) {
    .menuSwitchNoDisplay {
        display: none;
    }
    .siteMap {
        padding: 0em 0 0 0;
    }

}
@media all and (-ms-high-contrast: none) and (max-width: 1050.99px), (-ms-high-contrast: active) and (max-width: 1050.99px) {
    .menuSwitchNoDisplay {
        display: none;
    }
    .siteMap {
        padding: 0em 0 0 0;
    }
}

@media all and (max-width: 660.99px) and (min-width: 551px) {
    .siteMap {
        font-size: 1.42em;
    }
    .siteMapLink {
        padding: 0 0 0 .18em;
    }
    .verticalBarRight {
        border-right: .1em solid white;
        padding: 0 .18em 0 0;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 990.99px) and (min-width: 826px), (-ms-high-contrast: active) and (max-width: 990.99px) and (min-width: 826px) {
    .siteMap {
        font-size: 1.42em;
    }
    .siteMapLink {
        padding: 0 0 0 .18em;
    }
    .verticalBarRight {
        border-right: .1em solid white;
        padding: 0 .18em 0 0;
    }
}

@media all and (max-width: 600.99px) and (min-width: 331px) {
    .siteMap {
        font-size: 1.42em;
        flex-direction: column;
    }
    .siteMapLink {
        padding: 0 0 0 .3em;
    }
    .verticalBarRight {
        border-right: .11em solid white;
        padding: 0 .3em 0 0;
    }
    .lastBar {
        border-right: none;
        padding: 0;
    }
    .secondRow {
        padding: .25em 0 0 0;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 900.99px) and (min-width: 491px), (-ms-high-contrast: active) and (max-width: 900.99px) and (min-width: 491px) {
    .siteMap {
        font-size: 1.42em;
        flex-direction: column;
    }
    .siteMapLink {
        padding: 0 0 0 .3em;
    }
    .verticalBarRight {
        border-right: .11em solid white;
        padding: 0 .3em 0 0;
    }
    .lastBar {
        border-right: none;
        padding: 0;
    }
    .secondRow {
        padding: .25em 0 0 0;
    }
}

@media all and (max-width: 330.99px) and (min-width: 301px) {
    .siteMap {
        font-size: 1.19em;
        flex-direction: column;
    }
    .siteMapLink {
        padding: 0 0 0 .3em;
    }
    .verticalBarRight {
        border-right: .11em solid white;
        padding: 0 .3em 0 0;
    }
    .lastBar {
        border-right: none;
        padding: 0;
    }
    .secondRow {
        padding: .25em 0 0 0;
    }
    .breakSmaller {
        white-space: normal;
    }
    .breakChunk {
        white-space: nowrap;
    }
    .holder {
        padding: 0;
        margin: 0 1.25em;
        box-sizing: border-box;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 490.99px) and (min-width: 451px), (-ms-high-contrast: active) and (max-width: 490.99px) and (min-width: 451px){
    .siteMap {
        font-size: 1.19em;
        flex-direction: column;
    }
    .siteMapLink {
        padding: 0 0 0 .3em;
    }
    .verticalBarRight {
        border-right: .11em solid white;
        padding: 0 .3em 0 0;
    }
    .lastBar {
        border-right: none;
        padding: 0;
    }
    .secondRow {
        padding: .25em 0 0 0;
    }
    .breakSmaller {
        white-space: normal;
    }
    .breakChunk {
        white-space: nowrap;
    }
    .holder {
        padding: 0;
        margin: 0 1.25em;
        box-sizing: border-box;
    }
}

@media all and (max-width: 300.99px) {
    .siteMap {
        font-size: 1.05em;
        flex-direction: column;
    }
    .siteMapLink {
        padding: 0 0 0 .25em;
    }
    .verticalBarRight {
        border-right: .1em solid white;
        padding: 0 .25em 0 0;
    }
    .lastBar {
        border-right: none;
        padding: 0;
    }
    .secondRow {
        padding: .25em 0 0 0;
    }
    .breakSmaller {
        white-space: normal;
    }
    .breakChunk {
        white-space: nowrap;
    }
    .holder {
        padding: 0;
        margin: 0 1.2em;
        box-sizing: border-box;
    }
    .iconSize {
        width: 3em;
        height: 3em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 450.99px), (-ms-high-contrast: active) and (max-width: 450.99px) {
    .siteMap {
        font-size: 1.05em;
        flex-direction: column;
    }
    .siteMapLink {
        padding: 0 0 0 .25em;
    }
    .verticalBarRight {
        border-right: .1em solid white;
        padding: 0 .25em 0 0;
    }
    .lastBar {
        border-right: none;
        padding: 0;
    }
    .secondRow {
        padding: .25em 0 0 0;
    }
    .breakSmaller {
        white-space: normal;
    }
    .breakChunk {
        white-space: nowrap;
    }
    .holder {
        padding: 0;
        margin: 0 1.2em;
        box-sizing: border-box;
    }
    .iconSize {
        width: 3em;
        height: 3em;
    }
}



