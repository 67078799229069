.errorPage {
    display: flex;
    justify-content: center;
    align-items: center;
}

.blueHeading a {
    color: #f98a10;
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .blueHeading {
        font-size: 1.3em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .blueHeading {
        font-size: 1.3em;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    .blueHeading {
        font-size: 1.25em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .blueHeading {
        font-size: 1.25em;
    }
}


@media all and (max-width: 395.99px) {
    .blueHeading {
        font-size: 1.2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .blueHeading {
        font-size: 1.2em;
    }
}