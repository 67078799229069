.thumbnailsContainerGallery {
    display: flex;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.thumbnailImage {
    padding: 0 0 0 0;
    margin: 0 1.5% 1.5em 1.5%;
    box-sizing: border-box;
    width: auto;
    max-width: 30%;
    height: 10em;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: center;
}

.lastImage {
    flex: 1;
    max-width: 100%;
    min-width: 30%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
}

.thumbnailImage a {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.subgalleryImage {
    height: 100%;
    width: auto;
    cursor: pointer;
}

.titleArrowsGallery {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 1.88em 0;
}

.leftArrowTitle {
    flex: none;
    width: 1em;
    cursor: pointer;
}

.rightArrowTitle {
    flex: none;
    width: 1em;
    cursor: pointer;
}

.bottomLinkTitle {
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    padding: .5em 0 .89em 0;
    margin: 0;
    font-size: 1.5em;
    box-sizing: border-box;
    text-align: center;
}

.bottomLinkTitle a {
    text-decoration: underline;
    color: black;
}


@media all and (max-width: 700.99px) and (min-width: 421px){
    .thumbnailImage {
        max-width: 46%;
    }
    .thumbnailsContainerGallery {
        justify-content: space-around;
    }
    .lastImage {
        overflow: hidden;
        min-width: 46%;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1050.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 1050.99px) and (min-width: 631px) {
    .thumbnailImage {
       max-width: 46%;
    }
    .thumbnailsContainerGallery {
        justify-content: space-around;
    }
    .lastImage {
        overflow: hidden;
        min-width: 46%;
    }
}

@media all and (max-width: 420.99px) {
    .thumbnailImage {
        max-width: 100%;
    }
    .thumbnailsContainerGallery {
        justify-content: center;
    }
    .lastImage {
        overflow: hidden;
        min-width: 100%;
        justify-content: center;
    }
    .leftArrowTitle {
        width: .8em;
    }
    .rightArrowTitle {
        width: .8em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px), (-ms-high-contrast: active) and (max-width: 630.99px) {
    .thumbnailImage {
        max-width: 100%;
    }
    .thumbnailsContainerGallery {
        justify-content: center;
    }
    .lastImage {
        overflow: hidden;
        min-width: 100%;
        justify-content: center;
    }
    .leftArrowTitle {
        width: .8em;
    }
    .rightArrowTitle {
        width: .8em;
    }
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .bottomLinkTitle {
        font-size: 1.4em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .bottomLinkTitle {
        font-size: 1.4em;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    .bottomLinkTitle {
        font-size: 1.3em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .bottomLinkTitle {
        font-size: 1.3em;
    }
}

@media all and (max-width: 395.99px) {
    .bottomLinkTitle {
        font-size: 1.2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .bottomLinkTitle {
        font-size: 1.2em;
    }
}
