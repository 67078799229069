.titleContainer {
    box-sizing: border-box;
    background-color: #f9f9f9;
    display: flex;
    justify-content: space-between;
    padding: 2em;
    margin: 0;
}

.titleContainer a {
    text-decoration: none;
    color: inherit;
}

.logoHolder {
    flex: 1;
}

.logo {
    width: 70%;
}

.estimatesBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.estimatesTitle {
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: black;
    padding: 0 0 .25em 0;
    margin: 0;
    text-align: right;
    font-size: 1.15em;
}

.estimatesNumber {
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    color: #0072bb;
    padding: 0;
    margin: 0;
    text-align: right;
    font-size: 1.15em;
}

.navMenu {
    box-sizing: border-box;
    background-color: #0072bb;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: .5em solid #4d4d4d;
    padding: 0;
    margin: 0;
}

.navMenu a {
    color: white;
    text-decoration: none;
    margin: 0;
    padding: 0;
    flex: 1;
    box-sizing: border-box;
}

.largeLink {
    flex: 2 !important;
}

.menuLink {
    box-sizing: border-box;
    padding: .5em .75em;
    text-align: center;
    margin: 0;
    font-size: 1.25em;
}

.menuLinkHover:hover {
    background-color: #f98a10;
    transition: background-color .25s ease;
}

.menuLink:active {
    background-color: #f98a10;
}

.specialBanner {
    background-color: #f98a10;
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    padding: .25em;
    font-size: 1.15em;
    cursor: pointer;
}

.specialBanner a {
    color: white;
    text-decoration: none;
}

.specialBanner:hover {
    background-color: #4d4d4d;
}

.specialBanner:active {
    background-color: #4d4d4d;
}

.selectedPage {
    background-color: #dedfe2;
    color: black;
}

.mobileMenuToggle {
    display: none;
    background-color: #0072bb;
    color: white;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.4em;
    box-sizing: border-box;
    margin: 0;
    padding: .25em 0 .25em 0;
    flex: none;
    cursor: pointer;
}

.toggle {
    display: none;
    padding: .25em 0;
}

.toggleArrow {
    width: 8%;
    margin: 0 46%;
}

.mobileEstimates {
    display: none;
    background-color: #4d4d4d;
    color: white;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 1.3em;
    box-sizing: border-box;
    margin: 0;
    padding: .25em;
}

.menuSwitchDisplay {
    display: none;
}
@media all and (max-width: 1309.99px) {
    .menuLink {
        padding: .5em;
    }
}
@media all and (max-width: 1100.99px) and (min-width: 881px){
    .menuLink {
        font-size: 1.15em;
    }
}
@media all and (max-width: 880.99px) and (min-width: 851px){
    .menuLink {
        font-size: 1.1em;
    }
}
@media all and (max-width: 850.99px) and (min-width: 776px){
    .menuLink {
        font-size: 1.1em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1275.99px) and (min-width: 1161px), (-ms-high-contrast: active) and (max-width: 1275.99px) and (min-width: 1161px) {
    .menuLink {
        font-size: 1.1em;
    }
}

@media all and (max-width: 775.99px) and (min-width: 701px) {
    .menuLink {
        font-size: 1.14em;
        padding: .5em .6em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px) and (min-width: 1051px), (-ms-high-contrast: active) and (max-width: 1160.99px) and (min-width: 1051px) {
    .menuLink {
        font-size: 1.14em;
        padding: .5em .6em;
    }
}

@media all and (max-width: 700.99px) {
    .menuSwitchDisplay {
        display: block;
    }
    .mobileMenuHide {
        display: none;
    }
    .selectedPage {
        background-color: #dedfe2;
        color: #0072bb;
    }
    .menuLink:hover .hoverChange {
        color: #0072bb;
        transition: color .25s ease;
    }

    .menuLink:active .hoverChange {
        color: #0072bb;
    }
    .menuLinkHover:hover {
        background-color: #dedfe2;
    }
    .logo {
        width: 100%;
    }
}

@media all and (max-width: 500.99px) {
    .titleContainer {
        padding: 1.25em;
    }
}

@media all and (-ms-high-contrast: none) and (max-width: 1050.99px), (-ms-high-contrast: active) and (max-width: 1050.99px) {
    .menuSwitchDisplay {
        display: block;
    }
    .mobileMenuHide {
        display: none;
    }
    .selectedPage {
        background-color: #dedfe2;
        color: #0072bb;
    }
    .menuLink:hover .hoverChange {
        color: #0072bb;
        transition: color .25s ease;
    }

    .menuLink:active .hoverChange {
        color: #0072bb;
    }
    .menuLinkHover:hover {
        background-color: #dedfe2;
    }
}

@media all and (max-width: 700.99px) and (min-width: 501px) {
    .navMenu {
        flex-direction: column;
        background-color: #dedfe2;
        border: none;
        text-transform: capitalize;
        padding: .13em 0 .2em 0;
    }
    .navMenu a {
        color: black;
    }
    .menuLink {
        font-size: 1.35em;
        padding: .12em .6em;
    }
    .mobileMenuToggle {
        display: block;
    }

    .mobileEstimates {
        display: block;
    }
    .toggle {
        display: block;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1050.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 1050.99px) and (min-width: 751px) {
    .navMenu {
        flex-direction: column;
        background-color: #dedfe2;
        border: none;
        text-transform: capitalize;
        padding: .13em 0 .2em 0;
    }
    .navMenu a {
        color: black;
    }
    .menuLink {
        font-size: 1.35em;
        padding: .12em .6em;
    }
    .mobileMenuToggle {
        display: block;
    }
    .mobileEstimates {
        display: block;
    }
    .toggle {
        display: block;
    }
}

@media all and (max-width: 500.99px) and (min-width: 396px) {
    .navMenu {
        flex-direction: column;
        background-color: #dedfe2;
        border: none;
        text-transform: capitalize;
        padding: .13em 0 .2em 0;
    }
    .navMenu a {
        color: black;
    }
    .menuLink {
        font-size: 1.25em;
        padding: .12em .6em;
    }
    .mobileMenuToggle {
        display: block;
        font-size: 1.3em;
    }

    .mobileEstimates {
        display: block;
        font-size: 1.1em;
    }
    .toggle {
        display: block;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 591px) {
    .navMenu {
        flex-direction: column;
        background-color: #dedfe2;
        border: none;
        text-transform: capitalize;
        padding: .13em 0 .2em 0;
    }
    .navMenu a {
        color: black;
    }
    .menuLink {
        font-size: 1.25em;
        padding: .12em .6em;
    }
    .mobileMenuToggle {
        display: block;
        font-size: 1.3em;
    }

    .mobileEstimates {
        display: block;
        font-size: 1.1em;
    }
    .toggle {
        display: block;
    }
}

@media all and (max-width: 395.99px) {
    .navMenu {
        flex-direction: column;
        background-color: #dedfe2;
        border: none;
        text-transform: capitalize;
        padding: .13em 0 .2em 0;
    }
    .navMenu a {
        color: black;
    }
    .menuLink {
        font-size: 1.1em;
        padding: .08em .6em;
    }
    .mobileMenuToggle {
        display: block;
        font-size: 1.15em;
    }
    .toggle {
        display: block;
    }
    .mobileEstimates {
        display: block;
        font-size: 1em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .navMenu {
        flex-direction: column;
        background-color: #dedfe2;
        border: none;
        text-transform: capitalize;
        padding: .13em 0 .2em 0;
    }
    .navMenu a {
        color: black;
    }
    .menuLink {
        font-size: 1.1em;
        padding: .08em .6em;
    }
    .mobileMenuToggle {
        display: block;
        font-size: 1.15em;
    }
    .toggle {
        display: block;
    }
    .mobileEstimates {
        display: block;
        font-size: 1em;
    }
}




