.column {
    width: 50%;
}

.detailsHolderColumn {
    list-style: square;
    padding: 0 .85em;
    margin: 0;
    box-sizing: border-box;
    font-size: 1.25em;
}

.detail {
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 1em;
    box-sizing: border-box;
}

.drawingsHolder {
    width: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    display: flex;
}

.drawingSize {
    margin-right: .5em;
}

.floorPlanDrawings {
    height: 100%;
    display: block;
    padding: 0;
    border: solid #0072bb .2em;
}

.lastDrawing {
    margin: 0;
}

.thumbnailRowHolder {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    flex: 1;
}

.thumbnailRow {
    width: 18%;
    height: 10em;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background-color: lightblue;
}

.titleArrows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 .66em 0;
}

.leftArrowTitle {
    flex: none;
    width: 1em;
    cursor: pointer;
}

.rightArrowTitle {
    flex: none;
    width: 1em;
    cursor: pointer;
}

@media all and (max-width: 600.99px) {
    .detail {
        font-size: .9em;
    }
    .drawingsHolder {
        flex-direction: column;
    }
    .drawingSize {
        margin-right: 0;
        margin-bottom: .5em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 900.99px), (-ms-high-contrast: active) and (max-width: 900.99px) {
    .detail {
        font-size: .9em;
    }
    .drawingsHolder {
        flex-direction: column;
    }
    .drawingSize {
        margin-right: 0;
        margin-bottom: .5em;
    }
}

@media all and (max-width: 500.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .containerWhite {
        padding: 1em 1.5em 1.35em 1.5em;
    }

    .containerGrey {
        padding: 1em 1.5em 1.35em 1.5em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px), (-ms-high-contrast: active) and (max-width: 750.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .containerWhite {
        padding: 1em 1.5em 1.35em 1.5em;
    }

    .containerGrey {
        padding: 1em 1.5em 1.35em 1.5em;
    }
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .bottomLinkTitle {
        font-size: 1.4em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .bottomLinkTitle {
        font-size: 1.4em;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    .bottomLinkTitle {
        font-size: 1.3em;
    }
    .leftArrowTitle {
        width: .8em;
    }
    .rightArrowTitle {
        width: .8em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .bottomLinkTitle {
        font-size: 1.3em;
    }
    .leftArrowTitle {
        width: .8em;
    }
    .rightArrowTitle {
        width: .8em;
    }
}


@media all and (max-width: 395.99px) {
    .bottomLinkTitle {
        font-size: 1.2em;
    }
    .leftArrowTitle {
        width: .8em;
    }
    .rightArrowTitle {
        width: .8em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .bottomLinkTitle {
        font-size: 1.2em;
    }
    .leftArrowTitle {
        width: .8em;
    }
    .rightArrowTitle {
        width: .8em;
    }
}