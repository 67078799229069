.addBottomPadding {
    padding: 0 0 1.35em 0;
    margin: 0;
    box-sizing: border-box;
}

.center {
    text-align: center;
}

.blue {
    font-weight: bold;
    color: #0072bb;
}

.subheader {
    font-size: 1.25em;
    text-transform: none;
    font-family: 'Raleway', sans-serif;
    box-sizing: border-box;
    padding: 0 0 2em 0;
    font-weight: 700;
    text-align: center;
    margin: 0;
    color: #0072bb;
}

@media all and (max-width: 775.99px) and (min-width: 501px){
    .subheader {
        font-size: 1.2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 1160.99px) and (min-width: 751px) {
    .subheader {
        font-size: 1.2em;
    }
}


@media all and (max-width: 500.99px) and (min-width: 396px) {
    .subheader {
        font-size: 1.15em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 591px) {
    .subheader {
        font-size: 1.15em;
    }
}

@media all and (max-width: 395.99px) {
    .subheader {
        font-size: 1.1em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .subheader {
        font-size: 1.1em;
    }
}