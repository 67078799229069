.floorPlanImages {
    display: flex;
    align-items: center;
}

.imageScrollContainer {
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.transitionGroupHolder {
    display: flex;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.transitionGroupHolder span {
    display: flex;
    align-items: center;
}

.carouselImage {
    height: 10em;
    width: auto;
    z-index: -1000;
    padding: .5em;
    display: block;
    cursor: pointer;
}

.carouselImageHolder {
    flex: 1;
}

.carousel-enter {
}
.carousel-enter.carousel-enter-active {
    transform: translate(0%);
    transition: transform 1000ms ease-in-out;
}

.carousel-leave {
    transform: translate(0%);
}
.carousel-leave.carousel-leave-active {
    transform: translate(-100%);
    transition: transform 1000ms ease-in-out;
}
.carousel-leave.carousel-leave-active ~ img {
    transform: translate(-100%);
    transition: transform 1000ms ease-in-out;
}

.fullsizeModal {
    position: absolute;
    top: 4vh;
    left: 4vh;
    right: 4vh;
    bottom: 4vh;
    border: .1em solid #0072bb;
    background: #dedfe2;
    overflow: hidden;
    border-radius: .3em;
    outline: none;
    padding: 2vh;
    box-sizing: border-box;
}

.heightConstraint {
    height: 88vh;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fullsizeHolder {
    display: flex;
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex: 1;
}

.fullsizeHolder:focus {
    outline: none;
}

.mainImage {
    width: auto;
    max-width: 100%;
    box-sizing: border-box;
    display: block;
    max-height: 73vh;
    box-shadow: -10px 10px 30px 5px #888888;
}

.mainImageInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.counterHolder {
    font-family: 'Lato', sans-serif;
    text-align: center;
    font-weight: bold;
    padding-top: 3.5vh;
}

.arrowHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.5vh;
}

.arrow {
    flex: none;
    width: 1.5em;
    height: 1em;
    padding: 0;
    cursor: pointer;
}

.closeSymbol {
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    text-align: right;
    font-size: 1em;
    cursor: pointer;
    position: absolute;
    right: 2vh;
    top: 1.4vh;
}

.buttonBox {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.vhPadding {
    padding: .75vh;
}

@media all and (max-width: 500.99px) and (min-width: 396px) {
    .arrow {
        width: 2.5em;
        height: 2.5em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 591px) {
    .arrow {
        width: 2.5em;
        height: 2.5em;
    }
}

@media all and (max-width: 395.99px) and (min-width: 301px) {
    .arrow {
        width: 2.25em;
        height: 2.25em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px) and (min-width: 451px), (-ms-high-contrast: active) and (max-width: 590.99px) and (min-width: 451px) {
    .arrow {
        width: 2.25em;
        height: 2.25em;
    }
}

@media all and (max-width: 300.99px) {
    .arrow {
        width: 2em;
        height: 2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 450.99px), (-ms-high-contrast: active) and (max-width: 450.99px) {
    .arrow {
        width: 2em;
        height: 2em;
    }
}