.sloganLineOne {
    text-transform: uppercase;
    text-align: center;
    box-sizing: border-box;
    padding: 0em .75em .15em .75em;
    margin: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 2.75em;
}

.sloganLineTwo {
    color: #0072bb;
    text-transform: uppercase;
    text-align: center;
    box-sizing: border-box;
    padding: 0 .75em 1em .75em;
    margin: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 1.9em;
}

.blueButton {
    display: flex;
    box-sizing: border-box;
    padding: .75em .75em 0 .75em;
    margin: 0;
    justify-content: center;
}

.buttonText {
    background-color: #0072bb;
    color: white;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    border-radius: .25em;
    padding: .25em;
    margin: 0;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
}

.buttonText a {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.containerWhite {
    width: 100%;
    background-color: #f9f9f9;
    box-sizing: border-box;
    padding: 1em 2em 1.35em 2em;
    margin: 0;
}

.containerGrey {
    width: 100%;
    background-color: #dedfe2;
    box-sizing: border-box;
    padding: 1em 2em 1.35em 2em;
    margin: 0;
}

.columnContainer {
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.columnContainerHome {
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.imageContainerLeft {
    box-sizing: border-box;
    padding: .3em 2em 0 0;
    margin: 0;
    flex: 1;
}

.imageContainerRight {
    box-sizing: border-box;
    padding: .3em 0 0 2em;
    margin: 0;
    flex: 1.5;
}

.textContainer {
    flex: 1.5;
    box-sizing: border-box;
}

@media all and (max-width: 1245.99px) and (min-width: 1026px){
    .sloganLineOne {
        font-size: 2.6em;
    }

    .sloganLineTwo {
        font-size: 1.8em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1865.99px) and (min-width: 1536px), (-ms-high-contrast: active) and (max-width: 1865.99px) and (min-width: 1536px) {
    .sloganLineOne {
        font-size: 2.6em;
    }

    .sloganLineTwo {
        font-size: 1.8em;
    }
}

@media all and (max-width: 1025.99px) and (min-width: 851px){
    .imageContainerRight {
        flex: 1.25;
        padding: .3em 0 0 1.25em;
    }
    .imageContainerLeft {
        padding: .3em 1.25em 0 0;
    }
    .sloganLineOne {
        font-size: 2.5em;
    }

    .sloganLineTwo {
        font-size: 1.7em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1535.99px) and (min-width: 1276px), (-ms-high-contrast: active) and (max-width: 1535.99px) and (min-width: 1276px) {
    .imageContainerRight {
        flex: 1.25;
        padding: 0 0 0 1.25em;
    }
    .imageContainerLeft {
        padding: 0 1.25em 0 0;
    }
    .sloganLineOne {
        font-size: 2.5em;
    }

    .sloganLineTwo {
        font-size: 1.7em;
    }
}

@media all and (max-width: 850.99px) {
    .columnContainerHome {
        flex-direction: column;
        align-items: center;
    }
    .textContainer {
        order: 2;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1275.99px), (-ms-high-contrast: active) and (max-width: 1275.99px) {
    .columnContainerHome {
        flex-direction: column;
        align-items: center;
    }
    .textContainer {
        order: 2;
        flex: none;
        width: 100%;
    }
}

@media all and (max-width: 850.99px) and (min-width: 701px) {
    .imageContainerRight {
        flex: none;
        width: 60%;
        padding: 0 0 .6em 0;
    }
    .imageContainerLeft {
        flex: none;
        width: 60%;
        padding: 0 0 .6em 0;
        order: 1;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1275.99px) and (min-width: 1051px), (-ms-high-contrast: active) and (max-width: 1275.99px) and (min-width: 1051px) {
    .imageContainerRight {
        flex: none;
        width: 60%;
        padding: 0 0 .6em 0;
    }
    .imageContainerLeft {
        flex: none;
        width: 60%;
        padding: 0 0 .6em 0;
        order: 1;
    }
}

@media all and (max-width: 700.99px) {
    .imageContainerRight {
        flex: none;
        width: 70%;
        padding: 0 0 .6em 0;
    }
    .imageContainerLeft {
        flex: none;
        width: 70%;
        padding: 0 0 .6em 0;
        order: 1;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1050.99px), (-ms-high-contrast: active) and (max-width: 1050.99px) {
    .imageContainerRight {
        flex: none;
        width: 70%;
        padding: 0 0 .6em 0;
    }
    .imageContainerLeft {
        flex: none;
        width: 70%;
        padding: 0 0 .6em 0;
        order: 1;
    }
}

@media all and (max-width: 850.99px) and (min-width: 551px) {
    .sloganLineOne {
        font-size: 2.2em;
    }
    .sloganLineTwo {
        font-size: 1.65em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1275.99px) and (min-width: 826px), (-ms-high-contrast: active) and (max-width: 1275.99px) and (min-width: 826px) {
    .sloganLineOne {
        font-size: 2.2em;
    }
    .sloganLineTwo {
        font-size: 1.65em;
    }
}

@media all and (max-width: 550.99px) and (min-width: 501px) {
    .sloganLineOne {
        font-size: 2em;
    }
    .sloganLineTwo {
        font-size: 1.65em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 825.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 825.99px) and (min-width: 751px) {
    .sloganLineOne {
        font-size: 2em;
    }
    .sloganLineTwo {
        font-size: 1.65em;
    }
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .sloganLineOne {
        font-size: 1.7em;
    }
    .sloganLineTwo {
        font-size: 1.4em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .sloganLineOne {
        font-size: 1.7em;
    }
    .sloganLineTwo {
        font-size: 1.4em;
    }
}


@media all and (max-width: 500.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .containerWhite {
        padding: 1em 1.5em 1.35em 1.5em;
    }

    .containerGrey {
        padding: 1em 1.5em 1.35em 1.5em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px), (-ms-high-contrast: active) and (max-width: 750.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .containerWhite {
        padding: 1em 1.5em 1.35em 1.5em;
    }

    .containerGrey {
        padding: 1em 1.5em 1.35em 1.5em;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px){
    .sloganLineOne {
        font-size: 1.6em;
    }
    .sloganLineTwo {
        font-size: 1.2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .sloganLineOne {
        font-size: 1.6em;
    }
    .sloganLineTwo {
        font-size: 1.2em;
    }
}

@media all and (max-width: 395.99px) {
    .sloganLineOne {
        font-size: 1.4em;
    }
    .sloganLineTwo {
        font-size: 1.17em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .sloganLineOne {
        font-size: 1.4em;
    }
    .sloganLineTwo {
        font-size: 1.17em;
    }
}