.contactImage {
    padding: .35em 0 0 0;
}

.formField {
    font-size: .8em;
    width: 100%;
    margin: 0 0 1em 0;
    padding: .25em;
    background-color: white;
    border: #0072bb solid .1em;
    border-radius: .25em;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    box-shadow: none;
}

textarea {
    font-size: .8em;
    width: 100%;
    margin: 0 0 1em 0;
    padding: .25em;
    background-color: white;
    border: #0072bb solid .1em;
    border-radius: .25em;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    box-shadow: none;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-shadow: none;
}

.invalidAlert {
    font-size: .65em;
    color: #f98a10;
    margin-bottom: 1.53em;
    margin-top: -1em;
}

.contactForm {
    padding: 0em 0 0 2em;
    margin: 0;
    box-sizing: border-box;
}

.contactPrompt {
    font-size: 1.25em;
}


.largeBlueButton {
    display: flex;
    box-sizing: border-box;
    padding: 1em .75em 2em .75em;
    margin: 0;
    justify-content: center;
}

.largeBlueButton a {
    text-decoration: none;
}

.largeButtonText {
    background-color: #0072bb;
    color: white;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    border-radius: .25em;
    padding: .25em;
    margin: 0;
    box-sizing: border-box;
    font-size: 1.26em;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    border: none;
}

.largeButtonText a {
    text-decoration: none;
    color: white;
    box-sizing: border-box;
    cursor: pointer;
}

.blueButton {
    display: flex;
    box-sizing: border-box;
    padding: .75em .75em 0 .75em;
    margin: 0;
    justify-content: center;
}

.buttonText {
    background-color: #0072bb;
    color: white;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    border-radius: .25em;
    padding: .25em;
    margin: 0;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
}

.buttonText a {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.columnContainer {
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.imageColumn {
    flex: .75;
}

.contactColumn {
    flex: 1;
}

.columnContainerAuto {
    justify-content: space-around;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 2em 0 0em 0;
    margin: 0;
}

.columnAuto {
    text-align: center;
}

.contactDetailBold {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-weight: 700;
    color: #0072bb;
    font-size: 1.1em;
}

.contactDetailExtraSpace {
    box-sizing: border-box;
    margin: 0;
    padding: 0 0 1.18em 0;
    font-size: 1em;
}

.contactDetail {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 1em;
}

.contactDetailBoldCenter {
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-weight: 700;
    color: #0072bb;
    font-size: 1.1em;
}

.contactDetailCenter {
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 1em;
}

.horizontalDivider {
    display: none;
}

.horizontalDividerPresent {
    width: 50%;
    margin: 0 25%;
}

.wheatSymbols {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5em 0 1.3em 0;
}

.wheatSymbol {
    width: 7%;
    padding: 0;
    margin: 0 1em;
}

.noBottomPadding {
    padding-bottom: 0;
}

@media all and (max-width: 600.99px) {
    .imageColumn {
        display: none;
    }
    .contactForm {
        padding: 0em 2em 0 2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 900.99px), (-ms-high-contrast: active) and (max-width: 900.99px) {
    .imageColumn {
        display: none;
    }
    .contactForm {
        padding: 0em 2em 0 2em;
    }
}

@media all and (max-width: 660.99px) and (min-width: 551px) {
    .columnContainerAuto {
        font-size: .86em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 990.99px) and (min-width: 826px), (-ms-high-contrast: active) and (max-width: 990.99px) and (min-width: 826px) {
    .columnContainerAuto {
        font-size: .86em;
    }
}

@media all and (max-width: 550.99px) {
    .columnContainerAuto {
        font-size: 1em;
        flex-direction: column;
    }
    .contactDetailExtraSpace {
        padding: 0 0 .5em 0;
    }
    .horizontalDivider {
        display: block;
    }
    .horizontalDividerPresent {
        width: 100%;
        margin: 0;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 825.99px), (-ms-high-contrast: active) and (max-width: 825.99px) {
    .columnContainerAuto {
        font-size: 1em;
        flex-direction: column;
    }
    .contactDetailExtraSpace {
        padding: 0 0 .5em 0;
    }
    .horizontalDivider {
        display: block;
    }
    .horizontalDividerPresent {
        width: 100%;
        margin: 0;
    }
}

@media all and (max-width: 775.99px) {
    .contactDetailBold {
        font-size: 1.25em;
    }

    .contactDetailExtraSpace {
        font-size: 1.15em;
    }

    .contactDetail {
        font-size: 1.15em;
    }

    .contactDetailBoldCenter {
        font-size: 1.25em;
    }

    .contactDetailCenter {
        font-size: 1.15em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px), (-ms-high-contrast: active) and (max-width: 1160.99px) {
    .contactDetailBold {
        font-size: 1.25em;
    }

    .contactDetailExtraSpace {
        font-size: 1.15em;
    }

    .contactDetail {
        font-size: 1.15em;
    }

    .contactDetailBoldCenter {
        font-size: 1.25em;
    }

    .contactDetailCenter {
        font-size: 1.15em;
    }
}

@media all and (max-width: 500.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .containerWhite {
        padding: 1em 1.5em 1.35em 1.5em;
    }

    .containerGrey {
        padding: 1em 1.5em 1.35em 1.5em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px), (-ms-high-contrast: active) and (max-width: 750.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .containerWhite {
        padding: 1em 1.5em 1.35em 1.5em;
    }

    .containerGrey {
        padding: 1em 1.5em 1.35em 1.5em;
    }
}

@media all and (max-width: 775.99px) and (min-width: 501px){
    .largeButtonText {
        font-size: 1.3em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 1160.99px) and (min-width: 751px) {
    .largeButtonText {
        font-size: 1.3em;
    }
}


@media all and (max-width: 500.99px) and (min-width: 396px) {
    .largeButtonText {
        font-size: 1.25em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 591px) {
    .largeButtonText {
        font-size: 1.25em;
    }
}

@media all and (max-width: 395.99px) {
    .largeButtonText {
        font-size: 1.18em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .largeButtonText {
        font-size: 1.18em;
    }
}

@media all and (max-width: 395.99px) {
    .contactPrompt {
        font-size: 1.07em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .contactPrompt {
        font-size: 1.07em;
    }
}