@import url(https://fonts.googleapis.com/css?family=Lato:400,400i,700|Raleway:600,700);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html {
    box-sizing: border-box;
    max-width: 100%;
}

body {
    box-sizing: border-box;
    max-width: 100%;
    min-height: 100vh;
    background-color: #f9f9f9;
    display: flex;
    font-size: 18pt;
    font-family: 'Lato', sans-serif;
    padding: 0;
    margin: 0;
}

.lightboxOpenClass {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: fixed;
}

#root {
    box-sizing: border-box;
    min-height: 100vh;
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    background-color: #f9f9f9;
}

#root > div {
    box-sizing: border-box;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    background-color: #f9f9f9;
}

.pageWrapper {
    box-sizing: border-box;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    background-color: #f9f9f9;
}

.headerContainer {
    box-sizing: border-box;
    width: 100%;
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
}

.contentWrapper {
    box-sizing: border-box;
    width: 100%;
    flex: 1 1;
    margin: 0;
    padding: 0;
    font-size: 1em;
}

.fullWidthPhoto {
    box-sizing: border-box;
    padding: 0 0 0 0;
    margin: 0 0 1.5em 0;
    width: 100%;
}

.fullWidthPhoto img {
    border-bottom: solid .5em #0072bb;
}

.pageContentWithGrey {
    box-sizing: border-box;
    padding: 0 0 .65em 0;
    margin: 0;
}

.sidePadding {
    box-sizing: border-box;
    padding: 0 2em 2em 2em;
    margin: 0;
}

.pageContent {
    box-sizing: border-box;
    padding: 0 2em .65em 2em;
    margin: 0;
}

.footerContainer {
    box-sizing: border-box;
    width: 100%;
    flex: none;
    background-color: #4d4d4d;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2em 2.75em 2.5em 2.75em;
    margin: 0;
    font-size: .75em;
}

.footerContainer a {
    color: white;
    -webkit-text-decoration: white;
            text-decoration: white;
}

.italic {
    font-style: italic;
}

h1 {
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    padding: 0 0 .4em 0;
    margin: 0;
    font-size: 1.65em;
    box-sizing: border-box;
}

.contentPadding {
    padding: 0 0 1.14em 0;
}

h2 {
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    padding: 0 0 .4em 0;
    margin: 0;
    font-size: 1.5em;
    box-sizing: border-box;
    text-align: left;
}

h3 {
    font-size: 1em;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    box-sizing: border-box;
    padding: 0 0 .4em 0;
    font-weight: 700;
    text-align: left;
    margin: 0;
}

.contactPrompt {
    font-size: 1em;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    box-sizing: border-box;
    padding: 0 0 .4em 0;
    font-weight: 700;
    text-align: left;
    margin: 0;
}

.rightText {
    text-align: right;
}

p {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-size: 1.1em;
}

.paragraphText {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-size: 1.1em;
}

.multipleParagraphText {
    box-sizing: border-box;
    padding: .75em 0 0 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-size: 1.1em;
}

.lastParagraphText {
    box-sizing: border-box;
    padding: .75em 0 2em 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-size: 1.1em;
}

.noBottomPadding {
    padding-bottom: 0;
}

.noTopPadding {
    padding-top: 0;
}

img {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.noDisplay {
    display: none;
}

.hidden {
    visibility: hidden;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    body {
        font-size: 22pt;
    }
}
@media all and (max-width: 1245.99px) and (min-width: 1026px){
    body {
        font-size: 16pt;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1865.99px) and (min-width: 1535px), (-ms-high-contrast: active) and (max-width: 1865.99px) and (min-width: 1536px) {
    body {
        font-size: 20pt;
    }
}

@media all and (max-width: 1025.99px) and (min-width: 851px) {
    body {
        font-size: 14pt;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1535.99px) and (min-width: 1276px), (-ms-high-contrast: active) and (max-width: 1535.99px) and (min-width: 1275px){
    body {
        font-size: 18pt;
    }
}

@media all and (max-width: 850.99px) and (min-width: 776px) {
    .rightText {
        text-align: left;
    }
    body {
        font-size: 13pt;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1275.99px) and (min-width: 1161px), (-ms-high-contrast: active) and (max-width: 1275.99px) and (min-width: 1161px) {
    .rightText {
        text-align: left;
    }
    body {
        font-size: 17pt;
    }
}

@media all and (max-width: 775.99px) and (min-width: 501px){
    .rightText {
        text-align: left;
    }
    p {
        font-size: 1.25em;
    }
    .paragraphText {
        font-size: 1.25em;
    }

    .multipleParagraphText {
        font-size: 1.25em;
    }

    .lastParagraphText {
        font-size: 1.25em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 1160.99px) and (min-width: 751px) {
    .rightText {
        text-align: left;
    }
    p {
        font-size: 1.25em;
    }

    .paragraphText {
        font-size: 1.25em;
    }

    .multipleParagraphText {
        font-size: 1.25em;
    }

    .lastParagraphText {
        font-size: 1.25em;
    }
}

@media all and (max-width: 775.99px) and (min-width: 601px) {
    body {
        font-size: 11pt;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px) and (min-width: 901px), (-ms-high-contrast: active) and (max-width: 1160.99px) and (min-width: 901px){
    body {
        font-size: 15pt;
    }
}

@media all and (max-width: 700.99px) {
    .menuSwitchNoDisplay {
        display: none;
    }
    h1 {
        text-align: center;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1050.99px), (-ms-high-contrast: active) and (max-width: 1050.99px) {
    .menuSwitchNoDisplay {
        display: none;
    }
    h1 {
        text-align: center;
    }
}

@media all and (max-width: 600.99px) {
    body {
        font-size: 11pt;
    }
    .fullWidthPhoto {
        margin: 0 0 1em 0;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 900.99px), (-ms-high-contrast: active) and (max-width: 900.99px) {
    body {
        font-size: 15pt;
    }
    .fullWidthPhoto {
        margin: 0 0 1em 0;
    }
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    h2 {
        font-size: 1.4em;
    }
    h1 {
        font-size: 1.55em;
    }
    h3 {
        font-size: .95em;
    }
    .contactPrompt {
        font-size: .95em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    h2 {
        font-size: 1.4em;
    }
    h1 {
        font-size: 1.55em;
    }
    h3 {
        font-size: .95em;
    }
    .contactPrompt {
        font-size: .95em;
    }
}

@media all and (max-width: 500.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .sidePadding {
        padding: 0 1.5em 2em 1.5em;
    }
    .pageContent {
        padding: 0 1.5em .65em 1.5em;
    }
    .footerContainer {
        padding: 2em 2.06em 2.5em 2.06em;
    }
    .rightText {
        text-align: left;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px), (-ms-high-contrast: active) and (max-width: 750.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .sidePadding {
        padding: 0 1.5em 2em 1.5em;
    }
    .pageContent {
        padding: 0 1.5em .65em 1.5em;
    }
    .footerContainer {
        padding: 2em 2.06em 2.5em 2.06em;
    }
    .rightText {
        text-align: left;
    }
}

@media all and (max-width: 500.99px) and (min-width: 396px) {
    p {
        font-size: 1.14em;
    }
    .paragraphText {
        font-size: 1.14em;
    }
    .multipleParagraphText {
        font-size: 1.14em;
    }
    .lastParagraphText {
        font-size: 1.14em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 591px) {
    p {
        font-size: 1.14em;
    }
    .paragraphText {
        font-size: 1.14em;
    }
    .multipleParagraphText {
        font-size: 1.14em;
    }
    .lastParagraphText {
        font-size: 1.14em;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    h2 {
        font-size: 1.3em;
    }
    h1 {
        font-size: 1.45em;
    }
    h3 {
        font-size: .9em;
    }
    .contactPrompt {
        font-size: .9em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    h2 {
        font-size: 1.3em;
    }
    h1 {
        font-size: 1.45em;
    }
    h3 {
        font-size: .9em;
    }
    .contactPrompt {
        font-size: .9em;
    }
}

@media all and (max-width: 395.99px) {
    h2 {
        font-size: 1.2em;
    }
    h1 {
        font-size: 1.35em;
    }
    h3 {
        font-size: .85em;
    }
    .contactPrompt {
        font-size: .85em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    h2 {
        font-size: 1.2em;
    }
    h1 {
        font-size: 1.35em;
    }
    h3 {
        font-size: .85em;
    }
    .contactPrompt {
        font-size: .85em;
    }
}

@media all and (max-width: 395.99px) {
    p {
        font-size: 1.1em;
    }
    .paragraphText {
        font-size: 1.1em;
    }
    .multipleParagraphText {
        font-size: 1.1em;
    }
    .lastParagraphText {
        font-size: 1.1em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    p {
        font-size: 1.1em;
    }
    .paragraphText {
        font-size: 1.1em;
    }
    .multipleParagraphText {
        font-size: 1.1em;
    }
    .lastParagraphText {
        font-size: 1.1em;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .contentWrapper {
        flex: none !important;
    }
    .fullWidthPhoto {
        flex: none !important;
    }
    .flexNoneIE {
        flex: none !important;
    }
}





.titleContainer {
    box-sizing: border-box;
    background-color: #f9f9f9;
    display: flex;
    justify-content: space-between;
    padding: 2em;
    margin: 0;
}

.titleContainer a {
    text-decoration: none;
    color: inherit;
}

.logoHolder {
    flex: 1 1;
}

.logo {
    width: 70%;
}

.estimatesBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.estimatesTitle {
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: black;
    padding: 0 0 .25em 0;
    margin: 0;
    text-align: right;
    font-size: 1.15em;
}

.estimatesNumber {
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    color: #0072bb;
    padding: 0;
    margin: 0;
    text-align: right;
    font-size: 1.15em;
}

.navMenu {
    box-sizing: border-box;
    background-color: #0072bb;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: .5em solid #4d4d4d;
    padding: 0;
    margin: 0;
}

.navMenu a {
    color: white;
    text-decoration: none;
    margin: 0;
    padding: 0;
    flex: 1 1;
    box-sizing: border-box;
}

.largeLink {
    flex: 2 1 !important;
}

.menuLink {
    box-sizing: border-box;
    padding: .5em .75em;
    text-align: center;
    margin: 0;
    font-size: 1.25em;
}

.menuLinkHover:hover {
    background-color: #f98a10;
    transition: background-color .25s ease;
}

.menuLink:active {
    background-color: #f98a10;
}

.specialBanner {
    background-color: #f98a10;
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    padding: .25em;
    font-size: 1.15em;
    cursor: pointer;
}

.specialBanner a {
    color: white;
    text-decoration: none;
}

.specialBanner:hover {
    background-color: #4d4d4d;
}

.specialBanner:active {
    background-color: #4d4d4d;
}

.selectedPage {
    background-color: #dedfe2;
    color: black;
}

.mobileMenuToggle {
    display: none;
    background-color: #0072bb;
    color: white;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.4em;
    box-sizing: border-box;
    margin: 0;
    padding: .25em 0 .25em 0;
    flex: none;
    cursor: pointer;
}

.toggle {
    display: none;
    padding: .25em 0;
}

.toggleArrow {
    width: 8%;
    margin: 0 46%;
}

.mobileEstimates {
    display: none;
    background-color: #4d4d4d;
    color: white;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 1.3em;
    box-sizing: border-box;
    margin: 0;
    padding: .25em;
}

.menuSwitchDisplay {
    display: none;
}
@media all and (max-width: 1309.99px) {
    .menuLink {
        padding: .5em;
    }
}
@media all and (max-width: 1100.99px) and (min-width: 881px){
    .menuLink {
        font-size: 1.15em;
    }
}
@media all and (max-width: 880.99px) and (min-width: 851px){
    .menuLink {
        font-size: 1.1em;
    }
}
@media all and (max-width: 850.99px) and (min-width: 776px){
    .menuLink {
        font-size: 1.1em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1275.99px) and (min-width: 1161px), (-ms-high-contrast: active) and (max-width: 1275.99px) and (min-width: 1161px) {
    .menuLink {
        font-size: 1.1em;
    }
}

@media all and (max-width: 775.99px) and (min-width: 701px) {
    .menuLink {
        font-size: 1.14em;
        padding: .5em .6em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px) and (min-width: 1051px), (-ms-high-contrast: active) and (max-width: 1160.99px) and (min-width: 1051px) {
    .menuLink {
        font-size: 1.14em;
        padding: .5em .6em;
    }
}

@media all and (max-width: 700.99px) {
    .menuSwitchDisplay {
        display: block;
    }
    .mobileMenuHide {
        display: none;
    }
    .selectedPage {
        background-color: #dedfe2;
        color: #0072bb;
    }
    .menuLink:hover .hoverChange {
        color: #0072bb;
        transition: color .25s ease;
    }

    .menuLink:active .hoverChange {
        color: #0072bb;
    }
    .menuLinkHover:hover {
        background-color: #dedfe2;
    }
    .logo {
        width: 100%;
    }
}

@media all and (max-width: 500.99px) {
    .titleContainer {
        padding: 1.25em;
    }
}

@media all and (-ms-high-contrast: none) and (max-width: 1050.99px), (-ms-high-contrast: active) and (max-width: 1050.99px) {
    .menuSwitchDisplay {
        display: block;
    }
    .mobileMenuHide {
        display: none;
    }
    .selectedPage {
        background-color: #dedfe2;
        color: #0072bb;
    }
    .menuLink:hover .hoverChange {
        color: #0072bb;
        transition: color .25s ease;
    }

    .menuLink:active .hoverChange {
        color: #0072bb;
    }
    .menuLinkHover:hover {
        background-color: #dedfe2;
    }
}

@media all and (max-width: 700.99px) and (min-width: 501px) {
    .navMenu {
        flex-direction: column;
        background-color: #dedfe2;
        border: none;
        text-transform: capitalize;
        padding: .13em 0 .2em 0;
    }
    .navMenu a {
        color: black;
    }
    .menuLink {
        font-size: 1.35em;
        padding: .12em .6em;
    }
    .mobileMenuToggle {
        display: block;
    }

    .mobileEstimates {
        display: block;
    }
    .toggle {
        display: block;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1050.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 1050.99px) and (min-width: 751px) {
    .navMenu {
        flex-direction: column;
        background-color: #dedfe2;
        border: none;
        text-transform: capitalize;
        padding: .13em 0 .2em 0;
    }
    .navMenu a {
        color: black;
    }
    .menuLink {
        font-size: 1.35em;
        padding: .12em .6em;
    }
    .mobileMenuToggle {
        display: block;
    }
    .mobileEstimates {
        display: block;
    }
    .toggle {
        display: block;
    }
}

@media all and (max-width: 500.99px) and (min-width: 396px) {
    .navMenu {
        flex-direction: column;
        background-color: #dedfe2;
        border: none;
        text-transform: capitalize;
        padding: .13em 0 .2em 0;
    }
    .navMenu a {
        color: black;
    }
    .menuLink {
        font-size: 1.25em;
        padding: .12em .6em;
    }
    .mobileMenuToggle {
        display: block;
        font-size: 1.3em;
    }

    .mobileEstimates {
        display: block;
        font-size: 1.1em;
    }
    .toggle {
        display: block;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 591px) {
    .navMenu {
        flex-direction: column;
        background-color: #dedfe2;
        border: none;
        text-transform: capitalize;
        padding: .13em 0 .2em 0;
    }
    .navMenu a {
        color: black;
    }
    .menuLink {
        font-size: 1.25em;
        padding: .12em .6em;
    }
    .mobileMenuToggle {
        display: block;
        font-size: 1.3em;
    }

    .mobileEstimates {
        display: block;
        font-size: 1.1em;
    }
    .toggle {
        display: block;
    }
}

@media all and (max-width: 395.99px) {
    .navMenu {
        flex-direction: column;
        background-color: #dedfe2;
        border: none;
        text-transform: capitalize;
        padding: .13em 0 .2em 0;
    }
    .navMenu a {
        color: black;
    }
    .menuLink {
        font-size: 1.1em;
        padding: .08em .6em;
    }
    .mobileMenuToggle {
        display: block;
        font-size: 1.15em;
    }
    .toggle {
        display: block;
    }
    .mobileEstimates {
        display: block;
        font-size: 1em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .navMenu {
        flex-direction: column;
        background-color: #dedfe2;
        border: none;
        text-transform: capitalize;
        padding: .13em 0 .2em 0;
    }
    .navMenu a {
        color: black;
    }
    .menuLink {
        font-size: 1.1em;
        padding: .08em .6em;
    }
    .mobileMenuToggle {
        display: block;
        font-size: 1.15em;
    }
    .toggle {
        display: block;
    }
    .mobileEstimates {
        display: block;
        font-size: 1em;
    }
}





.footerContacts {
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    text-align: center;
}

.footerText {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.footerTextLine {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.siteMap {
    box-sizing: border-box;
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 1em 0 0 0;
    margin: 0;
    font-size: 1.55em;
    flex-wrap: wrap;
}

.siteMapLink {
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 0 0 .3em;
    margin: 0;
}

.siteMapLink a {
    text-decoration: none;
    color: white;
}

.verticalBarRight {
    box-sizing: border-box;
    border-right: .125em solid white;
    padding: 0 .3em 0 0;
    margin: 0;
}

.verticalBarRight a {
    text-decoration: none;
    color: white;
}

.firstRow {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    justify-content: center;
}

.secondRow {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    justify-content: center;
}

.footerCopyright {
    text-align: center;
    box-sizing: border-box;
    padding: 1.6em 0 2.1em 0;
    margin: 0;
}

.topPadding {
    padding-top: .75em;
}

.breakTogether {
    white-space: nowrap;
}

.winnovationsLink {
    text-decoration: underline;
}

.footerIcons {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.holder {
    padding: 0;
    margin: 0 1.75em;
    box-sizing: border-box;
}

.iconSize {
    width: 3.25em;
    height: 3.25em;
    padding: 0;
    margin: 0;
}

@media all and (max-width: 1025px) {
    .footerContacts {
        justify-content: space-between;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1535px), (-ms-high-contrast: active) and (max-width: 1535px) {
    .footerContacts {
        justify-content: space-between;
    }
}

@media all and (max-width: 850.99px) and (min-width: 661px) {
    .siteMap {
        font-size: 1.5em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1275.99px) and (min-width: 991px), (-ms-high-contrast: active) and (max-width: 1275.99px) and (min-width: 991px) {
    .siteMap {
        font-size: 1.5em;
    }
}

@media all and (max-width: 700.99px) {
    .menuSwitchNoDisplay {
        display: none;
    }
    .siteMap {
        padding: 0em 0 0 0;
    }

}
@media all and (-ms-high-contrast: none) and (max-width: 1050.99px), (-ms-high-contrast: active) and (max-width: 1050.99px) {
    .menuSwitchNoDisplay {
        display: none;
    }
    .siteMap {
        padding: 0em 0 0 0;
    }
}

@media all and (max-width: 660.99px) and (min-width: 551px) {
    .siteMap {
        font-size: 1.42em;
    }
    .siteMapLink {
        padding: 0 0 0 .18em;
    }
    .verticalBarRight {
        border-right: .1em solid white;
        padding: 0 .18em 0 0;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 990.99px) and (min-width: 826px), (-ms-high-contrast: active) and (max-width: 990.99px) and (min-width: 826px) {
    .siteMap {
        font-size: 1.42em;
    }
    .siteMapLink {
        padding: 0 0 0 .18em;
    }
    .verticalBarRight {
        border-right: .1em solid white;
        padding: 0 .18em 0 0;
    }
}

@media all and (max-width: 600.99px) and (min-width: 331px) {
    .siteMap {
        font-size: 1.42em;
        flex-direction: column;
    }
    .siteMapLink {
        padding: 0 0 0 .3em;
    }
    .verticalBarRight {
        border-right: .11em solid white;
        padding: 0 .3em 0 0;
    }
    .lastBar {
        border-right: none;
        padding: 0;
    }
    .secondRow {
        padding: .25em 0 0 0;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 900.99px) and (min-width: 491px), (-ms-high-contrast: active) and (max-width: 900.99px) and (min-width: 491px) {
    .siteMap {
        font-size: 1.42em;
        flex-direction: column;
    }
    .siteMapLink {
        padding: 0 0 0 .3em;
    }
    .verticalBarRight {
        border-right: .11em solid white;
        padding: 0 .3em 0 0;
    }
    .lastBar {
        border-right: none;
        padding: 0;
    }
    .secondRow {
        padding: .25em 0 0 0;
    }
}

@media all and (max-width: 330.99px) and (min-width: 301px) {
    .siteMap {
        font-size: 1.19em;
        flex-direction: column;
    }
    .siteMapLink {
        padding: 0 0 0 .3em;
    }
    .verticalBarRight {
        border-right: .11em solid white;
        padding: 0 .3em 0 0;
    }
    .lastBar {
        border-right: none;
        padding: 0;
    }
    .secondRow {
        padding: .25em 0 0 0;
    }
    .breakSmaller {
        white-space: normal;
    }
    .breakChunk {
        white-space: nowrap;
    }
    .holder {
        padding: 0;
        margin: 0 1.25em;
        box-sizing: border-box;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 490.99px) and (min-width: 451px), (-ms-high-contrast: active) and (max-width: 490.99px) and (min-width: 451px){
    .siteMap {
        font-size: 1.19em;
        flex-direction: column;
    }
    .siteMapLink {
        padding: 0 0 0 .3em;
    }
    .verticalBarRight {
        border-right: .11em solid white;
        padding: 0 .3em 0 0;
    }
    .lastBar {
        border-right: none;
        padding: 0;
    }
    .secondRow {
        padding: .25em 0 0 0;
    }
    .breakSmaller {
        white-space: normal;
    }
    .breakChunk {
        white-space: nowrap;
    }
    .holder {
        padding: 0;
        margin: 0 1.25em;
        box-sizing: border-box;
    }
}

@media all and (max-width: 300.99px) {
    .siteMap {
        font-size: 1.05em;
        flex-direction: column;
    }
    .siteMapLink {
        padding: 0 0 0 .25em;
    }
    .verticalBarRight {
        border-right: .1em solid white;
        padding: 0 .25em 0 0;
    }
    .lastBar {
        border-right: none;
        padding: 0;
    }
    .secondRow {
        padding: .25em 0 0 0;
    }
    .breakSmaller {
        white-space: normal;
    }
    .breakChunk {
        white-space: nowrap;
    }
    .holder {
        padding: 0;
        margin: 0 1.2em;
        box-sizing: border-box;
    }
    .iconSize {
        width: 3em;
        height: 3em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 450.99px), (-ms-high-contrast: active) and (max-width: 450.99px) {
    .siteMap {
        font-size: 1.05em;
        flex-direction: column;
    }
    .siteMapLink {
        padding: 0 0 0 .25em;
    }
    .verticalBarRight {
        border-right: .1em solid white;
        padding: 0 .25em 0 0;
    }
    .lastBar {
        border-right: none;
        padding: 0;
    }
    .secondRow {
        padding: .25em 0 0 0;
    }
    .breakSmaller {
        white-space: normal;
    }
    .breakChunk {
        white-space: nowrap;
    }
    .holder {
        padding: 0;
        margin: 0 1.2em;
        box-sizing: border-box;
    }
    .iconSize {
        width: 3em;
        height: 3em;
    }
}




.sloganLineOne {
    text-transform: uppercase;
    text-align: center;
    box-sizing: border-box;
    padding: 0em .75em .15em .75em;
    margin: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 2.75em;
}

.sloganLineTwo {
    color: #0072bb;
    text-transform: uppercase;
    text-align: center;
    box-sizing: border-box;
    padding: 0 .75em 1em .75em;
    margin: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 1.9em;
}

.blueButton {
    display: flex;
    box-sizing: border-box;
    padding: .75em .75em 0 .75em;
    margin: 0;
    justify-content: center;
}

.buttonText {
    background-color: #0072bb;
    color: white;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    border-radius: .25em;
    padding: .25em;
    margin: 0;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
}

.buttonText a {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.containerWhite {
    width: 100%;
    background-color: #f9f9f9;
    box-sizing: border-box;
    padding: 1em 2em 1.35em 2em;
    margin: 0;
}

.containerGrey {
    width: 100%;
    background-color: #dedfe2;
    box-sizing: border-box;
    padding: 1em 2em 1.35em 2em;
    margin: 0;
}

.columnContainer {
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.columnContainerHome {
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.imageContainerLeft {
    box-sizing: border-box;
    padding: .3em 2em 0 0;
    margin: 0;
    flex: 1 1;
}

.imageContainerRight {
    box-sizing: border-box;
    padding: .3em 0 0 2em;
    margin: 0;
    flex: 1.5 1;
}

.textContainer {
    flex: 1.5 1;
    box-sizing: border-box;
}

@media all and (max-width: 1245.99px) and (min-width: 1026px){
    .sloganLineOne {
        font-size: 2.6em;
    }

    .sloganLineTwo {
        font-size: 1.8em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1865.99px) and (min-width: 1536px), (-ms-high-contrast: active) and (max-width: 1865.99px) and (min-width: 1536px) {
    .sloganLineOne {
        font-size: 2.6em;
    }

    .sloganLineTwo {
        font-size: 1.8em;
    }
}

@media all and (max-width: 1025.99px) and (min-width: 851px){
    .imageContainerRight {
        flex: 1.25 1;
        padding: .3em 0 0 1.25em;
    }
    .imageContainerLeft {
        padding: .3em 1.25em 0 0;
    }
    .sloganLineOne {
        font-size: 2.5em;
    }

    .sloganLineTwo {
        font-size: 1.7em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1535.99px) and (min-width: 1276px), (-ms-high-contrast: active) and (max-width: 1535.99px) and (min-width: 1276px) {
    .imageContainerRight {
        flex: 1.25 1;
        padding: 0 0 0 1.25em;
    }
    .imageContainerLeft {
        padding: 0 1.25em 0 0;
    }
    .sloganLineOne {
        font-size: 2.5em;
    }

    .sloganLineTwo {
        font-size: 1.7em;
    }
}

@media all and (max-width: 850.99px) {
    .columnContainerHome {
        flex-direction: column;
        align-items: center;
    }
    .textContainer {
        order: 2;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1275.99px), (-ms-high-contrast: active) and (max-width: 1275.99px) {
    .columnContainerHome {
        flex-direction: column;
        align-items: center;
    }
    .textContainer {
        order: 2;
        flex: none;
        width: 100%;
    }
}

@media all and (max-width: 850.99px) and (min-width: 701px) {
    .imageContainerRight {
        flex: none;
        width: 60%;
        padding: 0 0 .6em 0;
    }
    .imageContainerLeft {
        flex: none;
        width: 60%;
        padding: 0 0 .6em 0;
        order: 1;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1275.99px) and (min-width: 1051px), (-ms-high-contrast: active) and (max-width: 1275.99px) and (min-width: 1051px) {
    .imageContainerRight {
        flex: none;
        width: 60%;
        padding: 0 0 .6em 0;
    }
    .imageContainerLeft {
        flex: none;
        width: 60%;
        padding: 0 0 .6em 0;
        order: 1;
    }
}

@media all and (max-width: 700.99px) {
    .imageContainerRight {
        flex: none;
        width: 70%;
        padding: 0 0 .6em 0;
    }
    .imageContainerLeft {
        flex: none;
        width: 70%;
        padding: 0 0 .6em 0;
        order: 1;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1050.99px), (-ms-high-contrast: active) and (max-width: 1050.99px) {
    .imageContainerRight {
        flex: none;
        width: 70%;
        padding: 0 0 .6em 0;
    }
    .imageContainerLeft {
        flex: none;
        width: 70%;
        padding: 0 0 .6em 0;
        order: 1;
    }
}

@media all and (max-width: 850.99px) and (min-width: 551px) {
    .sloganLineOne {
        font-size: 2.2em;
    }
    .sloganLineTwo {
        font-size: 1.65em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1275.99px) and (min-width: 826px), (-ms-high-contrast: active) and (max-width: 1275.99px) and (min-width: 826px) {
    .sloganLineOne {
        font-size: 2.2em;
    }
    .sloganLineTwo {
        font-size: 1.65em;
    }
}

@media all and (max-width: 550.99px) and (min-width: 501px) {
    .sloganLineOne {
        font-size: 2em;
    }
    .sloganLineTwo {
        font-size: 1.65em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 825.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 825.99px) and (min-width: 751px) {
    .sloganLineOne {
        font-size: 2em;
    }
    .sloganLineTwo {
        font-size: 1.65em;
    }
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .sloganLineOne {
        font-size: 1.7em;
    }
    .sloganLineTwo {
        font-size: 1.4em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .sloganLineOne {
        font-size: 1.7em;
    }
    .sloganLineTwo {
        font-size: 1.4em;
    }
}


@media all and (max-width: 500.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .containerWhite {
        padding: 1em 1.5em 1.35em 1.5em;
    }

    .containerGrey {
        padding: 1em 1.5em 1.35em 1.5em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px), (-ms-high-contrast: active) and (max-width: 750.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .containerWhite {
        padding: 1em 1.5em 1.35em 1.5em;
    }

    .containerGrey {
        padding: 1em 1.5em 1.35em 1.5em;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px){
    .sloganLineOne {
        font-size: 1.6em;
    }
    .sloganLineTwo {
        font-size: 1.2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .sloganLineOne {
        font-size: 1.6em;
    }
    .sloganLineTwo {
        font-size: 1.2em;
    }
}

@media all and (max-width: 395.99px) {
    .sloganLineOne {
        font-size: 1.4em;
    }
    .sloganLineTwo {
        font-size: 1.17em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .sloganLineOne {
        font-size: 1.4em;
    }
    .sloganLineTwo {
        font-size: 1.17em;
    }
}
.floorPlansTitleFirst {
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    padding: 1.3em 0 .75em 0;
    margin: 0;
    font-size: 1.5em;
    box-sizing: border-box;
    text-align: left;
}

.floorPlansTitle {
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    padding: .5em 0 .75em 0;
    margin: 0;
    font-size: 1.5em;
    box-sizing: border-box;
    text-align: left;
}

.blueBackground {
    background-color: #0072bb;
}

.thumbnailCategoryPlans {
    padding: 0 0 0 0;
    margin: 0 1.66% 1.5em 1.66%;
    box-sizing: border-box;
    width: 30%;
    display: flex;
    flex-direction: column;
    position: relative;
    border: solid #0072bb .05em;
}

.thumbnailCategoryPlans a {
    width: 100%;
    height: 100%;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: black;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
}

.backgroundImage {
    box-sizing: border-box;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.thumbnailText {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: rgb(255, 255, 255) transparent;
    background: rgba(255, 255, 255, 0.5);
}

.thumbnailTitle {
    font-size: 1em;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    box-sizing: border-box;
    padding: .75em;
    font-weight: 700;
    text-align: center;
    margin: 0;
}

.detailsAppearFloorPlans {
    padding: 0 .75em .75em .75em;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    display: none;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 1em;
    flex: 1 1;
}

.detailsHolder {
    list-style: square;
    padding: 0 .75em;
    margin: 0;
    box-sizing: border-box;
}

.floorPlanDetail {
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 1em;
    box-sizing: border-box;
}

.thumbnailText:hover {
    flex: 1 1;
}

.thumbnailText:hover .detailsAppearFloorPlans {
    display: flex;
    justify-content: center;
    align-items: center;
}

.thumbnailCategoryPlans:hover .thumbnailText {
    flex: 1 1;
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .floorPlansTitleFirst {
        font-size: 1.4em;
    }
    .floorPlansTitle {
        font-size: 1.4em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .floorPlansTitleFirst {
        font-size: 1.4em;
    }
    .floorPlansTitle {
        font-size: 1.4em;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    .floorPlansTitleFirst {
        font-size: 1.3em;
    }
    .floorPlansTitle {
        font-size: 1.3em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .floorPlansTitleFirst {
        font-size: 1.3em;
    }
    .floorPlansTitle {
        font-size: 1.3em;
    }
}


@media all and (max-width: 395.99px) {
    .floorPlansTitleFirst {
        font-size: 1.2em;
    }
    .floorPlansTitle {
        font-size: 1.2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .floorPlansTitleFirst {
        font-size: 1.2em;
    }
    .floorPlansTitle {
        font-size: 1.2em;
    }
}

@media all and (max-width: 850.99px) and (min-width: 501px){
    .thumbnailCategoryPlans {
        width: 46%;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1275.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 1275.99px) and (min-width: 751px) {
    .thumbnailCategoryPlans {
        width: 46%;
    }
}

@media all and (max-width: 775.99px) and (min-width: 501px){
    .detailsAppearFloorPlans {
        font-size: .8em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 1160.99px) and (min-width: 751px) {
    .detailsAppearFloorPlans {
        font-size: .8em;
    }
}

@media all and (max-width: 700.99px) {
    .menuSwitchNoDisplay {
        display: none;
    }
    .thumbnailText:hover {
        flex: none;
    }
    .thumbnailCategoryPlans:hover .thumbnailText {
        flex: none;
    }
    .detailsAppearFloorPlans {
        padding: 0;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1050.99px), (-ms-high-contrast: active) and (max-width: 1050.99px) {
    .menuSwitchNoDisplay {
        display: none;
    }
    .thumbnailText:hover {
        flex: none;
    }
    .thumbnailCategoryPlans:hover .thumbnailText {
        flex: none;
    }
    .detailsAppearFloorPlans {
        padding: 0;
    }
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .detailsAppearFloorPlans {
        font-size: 1.1em;
    }
    .thumbnailTitle {
        font-size: 1.1em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .detailsAppearFloorPlans {
        font-size: 1.1em;
    }
    .thumbnailTitle {
        font-size: 1.1em;
    }
}

@media all and (max-width: 500.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .thumbnailCategoryPlans {
        width: 100%;
        margin: 0 0 1.5em 0;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px), (-ms-high-contrast: active) and (max-width: 750.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .thumbnailCategoryPlans {
        width: 100%;
        margin: 0 0 1.5em 0;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    .detailsAppearFloorPlans {
        font-size: .95em;
    }
    .thumbnailTitle {
        font-size: .95em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .detailsAppearFloorPlans {
        font-size: .95em;
    }
    .thumbnailTitle {
        font-size: .95em;
    }
}

@media all and (max-width: 395.99px) {
    .detailsAppearFloorPlans {
        font-size: .85em;
    }
    .thumbnailTitle {
        font-size: .85em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .detailsAppearFloorPlans {
        font-size: .85em;
    }
    .thumbnailTitle {
        font-size: .85em;
    }
}

.thumbnailsContainer {
    display: flex;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.thumbnailCategory {
    padding: 0 0 0 0;
    margin: 0 1.66% 1.5em 1.66%;
    box-sizing: border-box;
    width: 30%;
    display: flex;
    flex-direction: column;
    position: relative;
    border: solid #0072bb .05em;
}

.thumbnailCategory a {
    width: 100%;
    height: 100%;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: black;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
}

.backgroundImage {
    box-sizing: border-box;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.thumbnailText {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgb(255, 255, 255) transparent;
    background: rgba(255, 255, 255, 0.5);
}

.thumbnailTitle {
    font-size: 1em;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    box-sizing: border-box;
    padding: .75em;
    font-weight: 700;
    text-align: center;
    margin: 0;
}

.detailsAppear {
    padding: .75em;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    display: none;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 1em;
}

.thumbnailText:hover {
    flex: 1 1;
}

.thumbnailText:hover .detailsAppear {
    display: block;
}

.thumbnailCategory:hover .thumbnailText {
    flex: 1 1;
}

@media all and (max-width: 1025.99px) and (min-width: 851px) {
    .detailsAppear {
        font-size: .95em;
    }
    .thumbnailTitle {
        font-size: .95em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1535.99px) and (min-width: 1276px), (-ms-high-contrast: active) and (max-width: 1535.99px) and (min-width: 1275px){
    .detailsAppear {
        font-size: .95em;
    }
    .thumbnailTitle {
        font-size: .95em;
    }
}

@media all and (max-width: 850.99px) and (min-width: 501px){
    .thumbnailCategory {
        width: 46%;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1275.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 1275.99px) and (min-width: 751px) {
    .thumbnailCategory {
        width: 46%;
    }
}

@media all and (max-width: 775.99px) and (min-width: 501px){
    .detailsAppear {
        font-size: .9em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 1160.99px) and (min-width: 751px) {
    .detailsAppear {
        font-size: .9em;
    }
}

@media all and (max-width: 700.99px) {
    .menuSwitchNoDisplay {
        display: none;
    }
    .thumbnailText:hover {
        flex: none;
    }
    .thumbnailCategory:hover .thumbnailText {
        flex: none;
    }
    .detailsAppear {
        padding: 0;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1050.99px), (-ms-high-contrast: active) and (max-width: 1050.99px) {
    .menuSwitchNoDisplay {
        display: none;
    }
    .thumbnailText:hover {
        flex: none;
    }
    .thumbnailCategory:hover .thumbnailText {
        flex: none;
    }
    .detailsAppear {
        padding: 0;
    }
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .detailsAppear {
        font-size: 1.1em;
    }
    .thumbnailTitle {
        font-size: 1.1em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .detailsAppear {
        font-size: 1.1em;
    }
    .thumbnailTitle {
        font-size: 1.1em;
    }
}

@media all and (max-width: 500.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .thumbnailCategory {
        width: 100%;
        margin: 0 0 1.5em 0;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px), (-ms-high-contrast: active) and (max-width: 750.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .thumbnailCategory {
        width: 100%;
        margin: 0 0 1.5em 0;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    .detailsAppear {
        font-size: .95em;
    }
    .thumbnailTitle {
        font-size: .95em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .detailsAppear {
        font-size: .95em;
    }
    .thumbnailTitle {
        font-size: .95em;
    }
}

@media all and (max-width: 395.99px) {
    .detailsAppear {
        font-size: .85em;
    }
    .thumbnailTitle {
        font-size: .85em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .detailsAppear {
        font-size: .85em;
    }
    .thumbnailTitle {
        font-size: .85em;
    }
}

.thumbnailCategoryPlans {
    cursor: pointer;
}

.thumbnailCategoryPlans .backgroundImage {
    cursor: pointer;
}
.floorPlanImages {
    display: flex;
    align-items: center;
}

.imageScrollContainer {
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.transitionGroupHolder {
    display: flex;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.transitionGroupHolder span {
    display: flex;
    align-items: center;
}

.carouselImage {
    height: 10em;
    width: auto;
    z-index: -1000;
    padding: .5em;
    display: block;
    cursor: pointer;
}

.carouselImageHolder {
    flex: 1 1;
}

.carousel-enter {
}
.carousel-enter.carousel-enter-active {
    -webkit-transform: translate(0%);
            transform: translate(0%);
    transition: -webkit-transform 1000ms ease-in-out;
    transition: transform 1000ms ease-in-out;
    transition: transform 1000ms ease-in-out, -webkit-transform 1000ms ease-in-out;
}

.carousel-leave {
    -webkit-transform: translate(0%);
            transform: translate(0%);
}
.carousel-leave.carousel-leave-active {
    -webkit-transform: translate(-100%);
            transform: translate(-100%);
    transition: -webkit-transform 1000ms ease-in-out;
    transition: transform 1000ms ease-in-out;
    transition: transform 1000ms ease-in-out, -webkit-transform 1000ms ease-in-out;
}
.carousel-leave.carousel-leave-active ~ img {
    -webkit-transform: translate(-100%);
            transform: translate(-100%);
    transition: -webkit-transform 1000ms ease-in-out;
    transition: transform 1000ms ease-in-out;
    transition: transform 1000ms ease-in-out, -webkit-transform 1000ms ease-in-out;
}

.fullsizeModal {
    position: absolute;
    top: 4vh;
    left: 4vh;
    right: 4vh;
    bottom: 4vh;
    border: .1em solid #0072bb;
    background: #dedfe2;
    overflow: hidden;
    border-radius: .3em;
    outline: none;
    padding: 2vh;
    box-sizing: border-box;
}

.heightConstraint {
    height: 88vh;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fullsizeHolder {
    display: flex;
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex: 1 1;
}

.fullsizeHolder:focus {
    outline: none;
}

.mainImage {
    width: auto;
    max-width: 100%;
    box-sizing: border-box;
    display: block;
    max-height: 73vh;
    box-shadow: -10px 10px 30px 5px #888888;
}

.mainImageInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.counterHolder {
    font-family: 'Lato', sans-serif;
    text-align: center;
    font-weight: bold;
    padding-top: 3.5vh;
}

.arrowHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.5vh;
}

.arrow {
    flex: none;
    width: 1.5em;
    height: 1em;
    padding: 0;
    cursor: pointer;
}

.closeSymbol {
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    text-align: right;
    font-size: 1em;
    cursor: pointer;
    position: absolute;
    right: 2vh;
    top: 1.4vh;
}

.buttonBox {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.vhPadding {
    padding: .75vh;
}

@media all and (max-width: 500.99px) and (min-width: 396px) {
    .arrow {
        width: 2.5em;
        height: 2.5em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 591px) {
    .arrow {
        width: 2.5em;
        height: 2.5em;
    }
}

@media all and (max-width: 395.99px) and (min-width: 301px) {
    .arrow {
        width: 2.25em;
        height: 2.25em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px) and (min-width: 451px), (-ms-high-contrast: active) and (max-width: 590.99px) and (min-width: 451px) {
    .arrow {
        width: 2.25em;
        height: 2.25em;
    }
}

@media all and (max-width: 300.99px) {
    .arrow {
        width: 2em;
        height: 2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 450.99px), (-ms-high-contrast: active) and (max-width: 450.99px) {
    .arrow {
        width: 2em;
        height: 2em;
    }
}
.blueHeading {
    color: #0072bb;
    text-transform: none;
    font-size: 1.4em;
    box-sizing: border-box;
}

.buildingTip {
    font-size: .9em;
    padding: 0 0 1.35em 0;
    box-sizing: border-box;
}

.actionText {
    font-size: 1em;
    box-sizing: border-box;
}

.largeBlueButton {
    display: flex;
    box-sizing: border-box;
    padding: 1em .75em 2em .75em;
    margin: 0;
    justify-content: center;
}

.largeButtonText {
    background-color: #0072bb;
    color: white;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    border-radius: .25em;
    padding: .25em;
    margin: 0;
    box-sizing: border-box;
    font-size: 1.26em;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}

.largeButtonText a {
    text-decoration: none;
    color: white;
    box-sizing: border-box;
    cursor: pointer;
}

.rightImage {
    width: 100%;
    margin: 0;
    padding: 0 0 0 1.5em;
    box-sizing: border-box;
    flex: 1 1;
 }

.leftImage {
    width: 100%;
    margin: 0;
    padding: 0 1.5em 0 0;
    box-sizing: border-box;
    flex: 1.5 1;
}

.columnContainer {
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.textFlexObject {
    flex: 1.5 1;
    box-sizing: border-box;
}

@media all and (max-width: 1025.99px) {
    .leftImage {
        flex: 1.25 1;
        padding: 0 1.25em 0 0;
    }
    .rightImage {
        padding: 0 0 0 1.25em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1535.99px), (-ms-high-contrast: active) and (max-width: 1535.99px) {
    .leftImage {
        flex: 1.25 1;
        padding: 0 1.25em 0 0;
    }
    .rightImage {
        padding: 0 0 0 1.25em;
    }
}

@media all and (max-width: 500.99px) {
    .buildingTip {
        padding: 0 0 1.35em 0;
        font-size: 1em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px), (-ms-high-contrast: active) and (max-width: 750.99px) {
    .buildingTip {
        padding: 0 0 1.35em 0;
        font-size: 1em;
    }
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .blueHeading {
        font-size: 1.3em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .blueHeading {
        font-size: 1.3em;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    .blueHeading {
        font-size: 1.25em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .blueHeading {
        font-size: 1.25em;
    }
}


@media all and (max-width: 395.99px) {
    .blueHeading {
        font-size: 1.2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .blueHeading {
        font-size: 1.2em;
    }
}

@media all and (max-width: 775.99px) and (min-width: 501px){
    .largeButtonText {
        font-size: 1.2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 1160.99px) and (min-width: 751px) {
    .largeButtonText {
        font-size: 1.2em;
    }
}


@media all and (max-width: 500.99px) and (min-width: 396px) {
    .largeButtonText {
        font-size: 1.15em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 591px) {
    .largeButtonText {
        font-size: 1.15em;
    }
}

@media all and (max-width: 395.99px) {
    .largeButtonText {
        font-size: 1em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .largeButtonText {
        font-size: 1em;
    }
}

.contactImage {
    padding: .35em 0 0 0;
}

.formField {
    font-size: .8em;
    width: 100%;
    margin: 0 0 1em 0;
    padding: .25em;
    background-color: white;
    border: #0072bb solid .1em;
    border-radius: .25em;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    box-shadow: none;
}

textarea {
    font-size: .8em;
    width: 100%;
    margin: 0 0 1em 0;
    padding: .25em;
    background-color: white;
    border: #0072bb solid .1em;
    border-radius: .25em;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    box-shadow: none;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-shadow: none;
}

.invalidAlert {
    font-size: .65em;
    color: #f98a10;
    margin-bottom: 1.53em;
    margin-top: -1em;
}

.contactForm {
    padding: 0em 0 0 2em;
    margin: 0;
    box-sizing: border-box;
}

.contactPrompt {
    font-size: 1.25em;
}


.largeBlueButton {
    display: flex;
    box-sizing: border-box;
    padding: 1em .75em 2em .75em;
    margin: 0;
    justify-content: center;
}

.largeBlueButton a {
    text-decoration: none;
}

.largeButtonText {
    background-color: #0072bb;
    color: white;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    border-radius: .25em;
    padding: .25em;
    margin: 0;
    box-sizing: border-box;
    font-size: 1.26em;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    border: none;
}

.largeButtonText a {
    text-decoration: none;
    color: white;
    box-sizing: border-box;
    cursor: pointer;
}

.blueButton {
    display: flex;
    box-sizing: border-box;
    padding: .75em .75em 0 .75em;
    margin: 0;
    justify-content: center;
}

.buttonText {
    background-color: #0072bb;
    color: white;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    border-radius: .25em;
    padding: .25em;
    margin: 0;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
}

.buttonText a {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.columnContainer {
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.imageColumn {
    flex: .75 1;
}

.contactColumn {
    flex: 1 1;
}

.columnContainerAuto {
    justify-content: space-around;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 2em 0 0em 0;
    margin: 0;
}

.columnAuto {
    text-align: center;
}

.contactDetailBold {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-weight: 700;
    color: #0072bb;
    font-size: 1.1em;
}

.contactDetailExtraSpace {
    box-sizing: border-box;
    margin: 0;
    padding: 0 0 1.18em 0;
    font-size: 1em;
}

.contactDetail {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 1em;
}

.contactDetailBoldCenter {
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-weight: 700;
    color: #0072bb;
    font-size: 1.1em;
}

.contactDetailCenter {
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 1em;
}

.horizontalDivider {
    display: none;
}

.horizontalDividerPresent {
    width: 50%;
    margin: 0 25%;
}

.wheatSymbols {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5em 0 1.3em 0;
}

.wheatSymbol {
    width: 7%;
    padding: 0;
    margin: 0 1em;
}

.noBottomPadding {
    padding-bottom: 0;
}

@media all and (max-width: 600.99px) {
    .imageColumn {
        display: none;
    }
    .contactForm {
        padding: 0em 2em 0 2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 900.99px), (-ms-high-contrast: active) and (max-width: 900.99px) {
    .imageColumn {
        display: none;
    }
    .contactForm {
        padding: 0em 2em 0 2em;
    }
}

@media all and (max-width: 660.99px) and (min-width: 551px) {
    .columnContainerAuto {
        font-size: .86em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 990.99px) and (min-width: 826px), (-ms-high-contrast: active) and (max-width: 990.99px) and (min-width: 826px) {
    .columnContainerAuto {
        font-size: .86em;
    }
}

@media all and (max-width: 550.99px) {
    .columnContainerAuto {
        font-size: 1em;
        flex-direction: column;
    }
    .contactDetailExtraSpace {
        padding: 0 0 .5em 0;
    }
    .horizontalDivider {
        display: block;
    }
    .horizontalDividerPresent {
        width: 100%;
        margin: 0;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 825.99px), (-ms-high-contrast: active) and (max-width: 825.99px) {
    .columnContainerAuto {
        font-size: 1em;
        flex-direction: column;
    }
    .contactDetailExtraSpace {
        padding: 0 0 .5em 0;
    }
    .horizontalDivider {
        display: block;
    }
    .horizontalDividerPresent {
        width: 100%;
        margin: 0;
    }
}

@media all and (max-width: 775.99px) {
    .contactDetailBold {
        font-size: 1.25em;
    }

    .contactDetailExtraSpace {
        font-size: 1.15em;
    }

    .contactDetail {
        font-size: 1.15em;
    }

    .contactDetailBoldCenter {
        font-size: 1.25em;
    }

    .contactDetailCenter {
        font-size: 1.15em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px), (-ms-high-contrast: active) and (max-width: 1160.99px) {
    .contactDetailBold {
        font-size: 1.25em;
    }

    .contactDetailExtraSpace {
        font-size: 1.15em;
    }

    .contactDetail {
        font-size: 1.15em;
    }

    .contactDetailBoldCenter {
        font-size: 1.25em;
    }

    .contactDetailCenter {
        font-size: 1.15em;
    }
}

@media all and (max-width: 500.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .containerWhite {
        padding: 1em 1.5em 1.35em 1.5em;
    }

    .containerGrey {
        padding: 1em 1.5em 1.35em 1.5em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px), (-ms-high-contrast: active) and (max-width: 750.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .containerWhite {
        padding: 1em 1.5em 1.35em 1.5em;
    }

    .containerGrey {
        padding: 1em 1.5em 1.35em 1.5em;
    }
}

@media all and (max-width: 775.99px) and (min-width: 501px){
    .largeButtonText {
        font-size: 1.3em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 1160.99px) and (min-width: 751px) {
    .largeButtonText {
        font-size: 1.3em;
    }
}


@media all and (max-width: 500.99px) and (min-width: 396px) {
    .largeButtonText {
        font-size: 1.25em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 591px) {
    .largeButtonText {
        font-size: 1.25em;
    }
}

@media all and (max-width: 395.99px) {
    .largeButtonText {
        font-size: 1.18em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .largeButtonText {
        font-size: 1.18em;
    }
}

@media all and (max-width: 395.99px) {
    .contactPrompt {
        font-size: 1.07em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .contactPrompt {
        font-size: 1.07em;
    }
}
.addBottomPadding {
    padding: 0 0 1.35em 0;
    margin: 0;
    box-sizing: border-box;
}

.center {
    text-align: center;
}

.blue {
    font-weight: bold;
    color: #0072bb;
}

.subheader {
    font-size: 1.25em;
    text-transform: none;
    font-family: 'Raleway', sans-serif;
    box-sizing: border-box;
    padding: 0 0 2em 0;
    font-weight: 700;
    text-align: center;
    margin: 0;
    color: #0072bb;
}

@media all and (max-width: 775.99px) and (min-width: 501px){
    .subheader {
        font-size: 1.2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1160.99px) and (min-width: 751px), (-ms-high-contrast: active) and (max-width: 1160.99px) and (min-width: 751px) {
    .subheader {
        font-size: 1.2em;
    }
}


@media all and (max-width: 500.99px) and (min-width: 396px) {
    .subheader {
        font-size: 1.15em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 591px) {
    .subheader {
        font-size: 1.15em;
    }
}

@media all and (max-width: 395.99px) {
    .subheader {
        font-size: 1.1em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .subheader {
        font-size: 1.1em;
    }
}
.buildingTip {
    font-size: .9em;
    padding: 0 0 1.35em 0;
    box-sizing: border-box;
    margin: 0;
}

.blueHeading {
    color: #0072bb;
    text-transform: none;
    font-size: 1.4em;
    box-sizing: border-box;
    margin: 0;
}

.blueHeadingUppercase {
    color: #0072bb;
    text-transform: uppercase;
    font-size: 1.6em;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.firstItem {
    padding: 2em 0 1.35em 0;
    box-sizing: border-box;
}

.listColumnContainer {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1em;
}

.processList {
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.processListItem {
    padding: 0 .5em .25em .5em;
    margin: 0;
}

.wheatBullet {
    width: 1em;
    height: 1em;
    flex: none;
    padding: .27em 0 0 0;
    margin: 0 .5em 0 0;
    box-sizing: border-box;
}

.processSublist {
    list-style: square;
    color: #0072bb;
    margin: 0;
    padding: 0 0 0 4em;
    box-sizing: border-box;
    font-size: .93em;
}

.processSublistItem {
    margin: 0;
    padding: .15em 0 0 0;
}

.blackText {
    color: black;
    box-sizing: border-box;
}

@media all and (max-width: 500.99px) {
    .buildingTip {
        padding: 0 0 1.35em 0;
        font-size: .95em;
    }
    .firstItem {
        padding: 2em 0 1em 0;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px), (-ms-high-contrast: active) and (max-width: 750.99px) {
    .buildingTip {
        padding: 0 0 1.35em 0;
        font-size: .95em;
    }
    .firstItem {
        padding: 2em 0 1em 0;
    }
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .blueHeading {
        font-size: 1.3em;
    }
    .blueHeadingUppercase {
        font-size: 1.5em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .blueHeading {
        font-size: 1.3em;
    }
    .blueHeadingUppercase {
        font-size: 1.5em;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    .blueHeading {
        font-size: 1.25em;
    }
    .blueHeadingUppercase {
        font-size: 1.45em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .blueHeading {
        font-size: 1.25em;
    }
    .blueHeadingUppercase {
        font-size: 1.45em;
    }
}


@media all and (max-width: 395.99px) {
    .blueHeading {
        font-size: 1.2em;
    }
    .blueHeadingUppercase {
        font-size: 1.4em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .blueHeading {
        font-size: 1.2em;
    }
    .blueHeadingUppercase {
        font-size: 1.4em;
    }
}


.thumbnailsContainerGallery {
    display: flex;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.thumbnailImage {
    padding: 0 0 0 0;
    margin: 0 1.5% 1.5em 1.5%;
    box-sizing: border-box;
    width: auto;
    max-width: 30%;
    height: 10em;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: center;
}

.lastImage {
    flex: 1 1;
    max-width: 100%;
    min-width: 30%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
}

.thumbnailImage a {
    width: 100%;
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.subgalleryImage {
    height: 100%;
    width: auto;
    cursor: pointer;
}

.titleArrowsGallery {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 1.88em 0;
}

.leftArrowTitle {
    flex: none;
    width: 1em;
    cursor: pointer;
}

.rightArrowTitle {
    flex: none;
    width: 1em;
    cursor: pointer;
}

.bottomLinkTitle {
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    padding: .5em 0 .89em 0;
    margin: 0;
    font-size: 1.5em;
    box-sizing: border-box;
    text-align: center;
}

.bottomLinkTitle a {
    text-decoration: underline;
    color: black;
}


@media all and (max-width: 700.99px) and (min-width: 421px){
    .thumbnailImage {
        max-width: 46%;
    }
    .thumbnailsContainerGallery {
        justify-content: space-around;
    }
    .lastImage {
        overflow: hidden;
        min-width: 46%;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 1050.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 1050.99px) and (min-width: 631px) {
    .thumbnailImage {
       max-width: 46%;
    }
    .thumbnailsContainerGallery {
        justify-content: space-around;
    }
    .lastImage {
        overflow: hidden;
        min-width: 46%;
    }
}

@media all and (max-width: 420.99px) {
    .thumbnailImage {
        max-width: 100%;
    }
    .thumbnailsContainerGallery {
        justify-content: center;
    }
    .lastImage {
        overflow: hidden;
        min-width: 100%;
        justify-content: center;
    }
    .leftArrowTitle {
        width: .8em;
    }
    .rightArrowTitle {
        width: .8em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px), (-ms-high-contrast: active) and (max-width: 630.99px) {
    .thumbnailImage {
        max-width: 100%;
    }
    .thumbnailsContainerGallery {
        justify-content: center;
    }
    .lastImage {
        overflow: hidden;
        min-width: 100%;
        justify-content: center;
    }
    .leftArrowTitle {
        width: .8em;
    }
    .rightArrowTitle {
        width: .8em;
    }
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .bottomLinkTitle {
        font-size: 1.4em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .bottomLinkTitle {
        font-size: 1.4em;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    .bottomLinkTitle {
        font-size: 1.3em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .bottomLinkTitle {
        font-size: 1.3em;
    }
}

@media all and (max-width: 395.99px) {
    .bottomLinkTitle {
        font-size: 1.2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .bottomLinkTitle {
        font-size: 1.2em;
    }
}

.column {
    width: 50%;
}

.detailsHolderColumn {
    list-style: square;
    padding: 0 .85em;
    margin: 0;
    box-sizing: border-box;
    font-size: 1.25em;
}

.detail {
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 1em;
    box-sizing: border-box;
}

.drawingsHolder {
    width: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    display: flex;
}

.drawingSize {
    margin-right: .5em;
}

.floorPlanDrawings {
    height: 100%;
    display: block;
    padding: 0;
    border: solid #0072bb .2em;
}

.lastDrawing {
    margin: 0;
}

.thumbnailRowHolder {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    flex: 1 1;
}

.thumbnailRow {
    width: 18%;
    height: 10em;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background-color: lightblue;
}

.titleArrows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 .66em 0;
}

.leftArrowTitle {
    flex: none;
    width: 1em;
    cursor: pointer;
}

.rightArrowTitle {
    flex: none;
    width: 1em;
    cursor: pointer;
}

@media all and (max-width: 600.99px) {
    .detail {
        font-size: .9em;
    }
    .drawingsHolder {
        flex-direction: column;
    }
    .drawingSize {
        margin-right: 0;
        margin-bottom: .5em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 900.99px), (-ms-high-contrast: active) and (max-width: 900.99px) {
    .detail {
        font-size: .9em;
    }
    .drawingsHolder {
        flex-direction: column;
    }
    .drawingSize {
        margin-right: 0;
        margin-bottom: .5em;
    }
}

@media all and (max-width: 500.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .containerWhite {
        padding: 1em 1.5em 1.35em 1.5em;
    }

    .containerGrey {
        padding: 1em 1.5em 1.35em 1.5em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px), (-ms-high-contrast: active) and (max-width: 750.99px) {
    .mobileNoDisplay {
        display: none;
    }
    .containerWhite {
        padding: 1em 1.5em 1.35em 1.5em;
    }

    .containerGrey {
        padding: 1em 1.5em 1.35em 1.5em;
    }
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .bottomLinkTitle {
        font-size: 1.4em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .bottomLinkTitle {
        font-size: 1.4em;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    .bottomLinkTitle {
        font-size: 1.3em;
    }
    .leftArrowTitle {
        width: .8em;
    }
    .rightArrowTitle {
        width: .8em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .bottomLinkTitle {
        font-size: 1.3em;
    }
    .leftArrowTitle {
        width: .8em;
    }
    .rightArrowTitle {
        width: .8em;
    }
}


@media all and (max-width: 395.99px) {
    .bottomLinkTitle {
        font-size: 1.2em;
    }
    .leftArrowTitle {
        width: .8em;
    }
    .rightArrowTitle {
        width: .8em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .bottomLinkTitle {
        font-size: 1.2em;
    }
    .leftArrowTitle {
        width: .8em;
    }
    .rightArrowTitle {
        width: .8em;
    }
}
.errorPage {
    display: flex;
    justify-content: center;
    align-items: center;
}

.blueHeading a {
    color: #f98a10;
}

@media all and (max-width: 500.99px) and (min-width: 421px) {
    .blueHeading {
        font-size: 1.3em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 750.99px) and (min-width: 631px), (-ms-high-contrast: active) and (max-width: 750.99px) and (min-width: 631px) {
    .blueHeading {
        font-size: 1.3em;
    }
}

@media all and (max-width: 420.99px) and (min-width: 396px) {
    .blueHeading {
        font-size: 1.25em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 630.99px) and (min-width: 591px), (-ms-high-contrast: active) and (max-width: 630.99px) and (min-width: 591px) {
    .blueHeading {
        font-size: 1.25em;
    }
}


@media all and (max-width: 395.99px) {
    .blueHeading {
        font-size: 1.2em;
    }
}
@media all and (-ms-high-contrast: none) and (max-width: 590.99px), (-ms-high-contrast: active) and (max-width: 590.99px) {
    .blueHeading {
        font-size: 1.2em;
    }
}
